import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { AnToastModule } from 'projects/dt-an-components-lib/an-toast';
import { AnHeartIconModule } from 'projects/dt-an-components-lib/an-heart-icon';
import { AnAuthenticationModule } from 'projects/dt-an-components-lib/authentication-modal';
import { AnSaveFavoritesModule } from 'projects/dt-an-components-lib/an-save-favorites';
import { AnModalModule } from 'projects/dt-an-components-lib/an-modal';
import { IframeComponent } from './iframe/iframe.component';
import { HoursComponent } from './hours/hours.component';
import { SafeSrcPipe } from './pipes/safe-src.pipe';
import { WeekDayPipe } from './pipes/week-day.pipe';
import { ConfirmCancelApptModalComponent } from './confirm/confirm-cancel-appt-modal.component';
import { DecimalNumberPipeModule, DecimalStringPipeModule } from 'projects/dt-an-components-lib/an-pipes';
import { AppRoutingModule } from './app-routing.module';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { PipesIndexComponent } from './pipes/pipes-index/pipes-index.component';
import { AppIndexComponent } from './components/app-index/app-index.component';
import { CardComponent } from './shared/components/card/card.component';
import { SharedModule } from './shared/shared.module';
import { AnVerifyEmailModule } from '@autonation/dt-an-components-lib/verify-email-modal';
import { AnAddvehicleModule } from '@autonation/dt-an-components-lib/an-add-vehicle-modal';
import { NotificationModule } from '@autonation/dt-an-components-lib/notification-modal';
import { AnComparevehicleModule } from '@autonation/dt-an-components-lib/an-compare-vehicle-modal';
import { AppointmentModule } from './appointment/appointment.module';



@NgModule({
  declarations: [
    SideBarComponent,
    AppComponent,
    IframeComponent,
    HoursComponent,
    SafeSrcPipe,
    WeekDayPipe,
    ConfirmCancelApptModalComponent,
    AppIndexComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AnToastModule,
    AnHeartIconModule,
    AnAuthenticationModule,
    AnVerifyEmailModule,
    AnSaveFavoritesModule,
    AnModalModule,
    AppRoutingModule,
    SharedModule,
    AnAddvehicleModule,
    AnComparevehicleModule,
    NotificationModule,
    AppointmentModule
  ],
  providers: [],
  // Commented due to issue in actual server, uncomment for local testing -->
  // Note: Please don't commit again -->
  // bootstrap: [AppComponent]
})
// Commented due to issue in actual server, uncomment for local testing, comment the 69 to 71 lines -->
// Note: Please don't commit again -->
// export class AppModule { }
export class AppModule {
  ngDoBootstrap() { }
}
