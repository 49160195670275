import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalString'
})
export class DecimalStringPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  transform(value: string | number | undefined): string | null {
    if (!value) {
      return null;
    }
    return this._decimalPipe.transform(
      parseFloat(value.toString().replace(/,/g, '')),
      '1.0-0'
    );
  }
}
