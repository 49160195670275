import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { State } from '../../models/appointment-models';

@Component({
  selector: 'an-input-state',
  templateUrl: './input-state.component.html',
  styleUrls: ['./input-state.component.scss']
})
export class InputStateComponent implements OnInit {

  @Input()
  states!: State[];

  @Input()
  stateControl!: FormControl;

  @Input()
  stateLabel!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
