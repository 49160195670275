import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-appt-time',
  templateUrl: './appt-time.component.html',
  styleUrls: ['./appt-time.component.scss']
})
export class ApptTimeComponent {

  @Input()
  timeTitle!: string;

  @Input()
  hours!: string[];

  @Input()
  timeControl!: FormControl;

}
