<div #modal aria-hidden="true" class="new-modal" (click)="closeAuthModal()" role="dialog" *ngIf="showAuthenticationModal">
  <div class="new-modal-dialog" [ngClass]="{'new-appt-modal-dialog': (isOwnershipAppt || isSRPSearch)
  }">
    <div id="auth-modal-centents" class="new-modal-content" (click)="$event.stopPropagation()">
      <ng-content></ng-content>
      <button #closeModalRef aria-label="Close dialog" class="new-modal-close" [ngClass]="{
        'new-modal-close-appt': (isOwnershipAppt || isSRPSearch)
      }" data-dismiss="modal"
        (click)="closeAuthModal()">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.27755 7.04376C8.61754 7.38391 8.61754 7.93349 8.27755 8.27365C8.10795 8.44326 7.88532 8.52845 7.66253 8.52845C7.43989 8.52845 7.21725 8.44326 7.04766 8.27365L4.43423 5.66005L1.82079 8.27365C1.65122 8.44326 1.42857 8.52845 1.20595 8.52845C0.983171 8.52845 0.760523 8.44326 0.590931 8.27365C0.25094 7.93349 0.25094 7.38391 0.590931 7.04376L3.2045 4.43036L0.590931 1.8169C0.25094 1.47675 0.25094 0.92717 0.590931 0.587031C0.931042 0.247031 1.48063 0.247031 1.82079 0.587031L4.43423 3.20061L7.04766 0.587031C7.38782 0.247031 7.93739 0.247031 8.27755 0.587031C8.61754 0.927179 8.61754 1.47676 8.27755 1.8169L5.66395 4.43036L8.27755 7.04376Z"
            fill="#949494" />
        </svg>

      </button>

      <div class="auth-modal-body">
        <div class="tableHeader" [ngClass]="{'tableHeaderApp': (isOwnershipAppt || isSRPSearch) }">
          {{isOwnershipAppt ? 'Edit Any Appointment!'  : isSRPSearch ? 'Never Miss a Beat' : 'Great Choice!'}}
        </div>
        <div class="image-sec"  [ngClass]="{'image-src': (isOwnershipAppt || isSRPSearch)}">
          <img *ngIf="isOwnershipAppt" [src]="apptModalImage | safeUrlPipeModal" />
          <img class="srp-image" *ngIf="isSRPSearch && !isOwnershipAppt" [src]="srpSearchModalImage | safeUrlPipeModal" />
          <img *ngIf="!isSRPSearch && !isOwnershipAppt" [src]="modalImage | safeUrlPipeModal" />
        </div>
        <div class="auth-text" [ngClass]="{'auth-text-appt': (isOwnershipAppt || isSRPSearch) }">
          {{ isOwnershipAppt ? 'So you want to edit your appointment, but why stop there? Sign in or create an account to take your experience to the next level.'
             : isSRPSearch ? 'Save your searches and stay in the loop! Sign in or create an account to take your experience to the next level.' : 'So you want to favorite a vehicle, but why stop there? Sign in or create an account to save all of your favorites and take your experience to the next level. ❤️'}}
        </div>
        <button class="auth-modal-btn sign-in-btn" [ngClass]="{'appt-btn': (isOwnershipAppt || isSRPSearch) }" (click)="closeAuthModal('login')">Sign In</button>
        <button class="auth-modal-btn create-account-btn" [ngClass]="{'appt-btn': (isOwnershipAppt || isSRPSearch) }" (click)="closeAuthModal('register')">Create Account</button>
        <div *ngIf="isOwnershipAppt" (click)="closeAuthModal('guest')" class="editAppt"  [ngClass]="{'appt-btn': isOwnershipAppt }">
          Edit Appointment as Guest
        </div>
      </div>
    </div>
  </div>
</div>