<div class="an-appt-date">
  <h1 class="an-appt-date__title"> {{ dateTitle }} </h1>

  <div class="an-appt-date__wrap">
    <div class="an-appt-date__item" *ngFor="let date of mappedDates" [ngClass]="{'checked': date.checked}">
      <label class="an-item-label" [for]="'date_'+ date.day">
        <div class="an-item-label__day-of-week"> {{ date.dayOfWeek }} </div>
        <div class="an-item-label__month"> {{ date.month }} </div>
        <div class="an-item-label__day"> {{ date.day }} </div>
      </label>
      <input class="an-appt-date__radio" type="radio" name="date" [id]="'date_'+ date.day" [value]="date.value"
        [formControl]="dateControl" (change)="changeDate(date)">
    </div>
  </div>
  
</div>
