import { Component, EventEmitter, HostListener, OnInit, Output, } from '@angular/core';
import { apptModalImage, bas64Image } from './notification-modal.constants';
import { AnNotificationModalService } from './notification-modal.service';
import { DatePipe } from '@angular/common';
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'notification-modal-lib',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalLibComponent implements OnInit {
    showNotificationModal: boolean = false;
    @Output() onModalClose: EventEmitter<string> = new EventEmitter<string>();
    notifDetail: any;
    markallasRead : boolean = false;
    modalImage = bas64Image;
    apptModalImage = apptModalImage;
    notificationDate_Time: any;
    storeDetails: any;
    data: any = null;
    tradeInData: any = [];
    serviceApptData: any = [];
    salesApptData: any = [];
    prequalData: any = {};
    cmsData : any = null;
    weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    tradeInPending: boolean = false;
    tradeInExpire: boolean = false;
    tradeInStatus: boolean = false;
    tradeInReject: boolean = false;
    tradeInnotificationDate_Time!: string;
    tradeIn: any = {};
    serviceNotify: any = {};
    tradeInText: string = '';
    tradeInVin: string = '';
    showWeOffer = false;
    weOffer: string = '';
    tradeinTimeNotif: string = '';
    hid!: string;
    apptDate_Time: string = '';
    appStoreName: string = '';
    apptInfoText: string = '';
    notify: any = {};
    notifyData: any = {};
    storeDetail: any = [];
    allData: any = [];
    sortedData: any = [];
    alertArray: any = [];
    myUpcomingAppointmentDetails: any = [];
    allAlert: any = [];
    enableMarkAllAsRead: boolean = false;
    preQualifiedAmt: any;
    constructor(private noticationModalService: AnNotificationModalService, private _date: DatePipe,) { }
    /* istanbul ignore next */

    ngOnInit() {
        this.notifDetail = this.noticationModalService.notifDetail;

        document.addEventListener("UpdateAllAlertStatus", (getAlerts) => {
            console.log("Received in Notification Model");
            this.markallAsRead();
        });
        
        
        if(this.isUserLoggedIn() && this.isAlertsEnabled()) {
            this.getAlerts();
        }

        this.noticationModalService.openNotificaion.subscribe((x: any) => {
            if(x) {
                this.showNotificationModal = true;
            } else {
                this.showNotificationModal = false;
            }
        })
        this.storeDetail = [
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2940",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2940/2940_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=DCD373912BB9B48A9357D4C59B92162E"
            },
            {
                "HyperionValue": "2130",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2130/2130_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=8A08D4D9F564069877B0F89E4B268DBA"
            },
            {
                "HyperionValue": "2942",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/Default/AutoNation_Default.png?h=313&iar=0&w=556&hash=8FCB3B3ECD7C194B55CE3C2B0A18E164"
            },
            {
                "HyperionValue": "2942",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/Default/AutoNation_Default.png?h=313&iar=0&w=556&hash=8FCB3B3ECD7C194B55CE3C2B0A18E164"
            },
            {
                "HyperionValue": "2942",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/Default/AutoNation_Default.png?h=313&iar=0&w=556&hash=8FCB3B3ECD7C194B55CE3C2B0A18E164"
            },
            {
                "HyperionValue": "2942",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/Default/AutoNation_Default.png?h=313&iar=0&w=556&hash=8FCB3B3ECD7C194B55CE3C2B0A18E164"
            },
            {
                "HyperionValue": "2942",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/Default/AutoNation_Default.png?h=313&iar=0&w=556&hash=8FCB3B3ECD7C194B55CE3C2B0A18E164"
            },
            {
                "HyperionValue": "2838",
                "ImageURL": "//www6.intautonation.com/~/media/Images/Stores/Images/2838/2838_v001_0556_0313.jpg?h=313&iar=0&w=556&hash=F56B9CCB9BC2AB30999432E9EA2027E4"
            }
        ];
        this.cmsData = {
            "MyNotifTitle": "Notifications",
            "MyNotifSubtitle": "Stay in the know. Here you can view all the latest updates and important information about your account.",
            "NoNotifcations_Title": "Whoops, nothing to see here!",
            "UpcomingAppointment": "Upcoming Appointment",
            "UpcomingServiceAppointment": "Upcoming Service Appointment",
            "ViewMore": "View More",
            "ViewLess": "View Less",
            "AppointmentInfo": "Appointment Info",
            "AppointmentDetails": "Appointment Details",
            "ApptInfoText": "{{apptWeekday}}, {{apptDate}} at {{apptTime}} at {{apptStoreName}}",
            "Date_Time": "Date & Time",
            "Location": "Location",
            "PhoneNumber": "Phone Number",
            "AddApptCalendar": "Add to Calendar",
            "CancelAppointment": "Cancel Appointment",
            "CancelServiceAppointment": "Cancel or Edit Appointment",
            "EditAppointment": "Edit Appointment",
            "WeOffer": "We're Happy to Offer You {{amount}}",
            "TradeIn": "Trade in your {{year}} {{makeModel}} today. We'll even pick it up!",
            "YouPrequalified": "You're Prequalified",
            "Qualify": "You qualify for financing up to ",
            "PrequalNeedMoreInfo":"In order for us to approve your financing request we need you to contact us. Chat with us today!",
            "Disclaimer": "Disclaimer",
            "DisclaimerText": "Quoted payments are based on select available incentives and may not be available in combination with certain incentives shown above. {{months}} months at {{APR}} APR financing with ${{downpayment}} down. Payment includes ${{dealerfees}} dealer service fees. Plus sales tax or other taxes, tag, registration fees, and government fees. With approved Excellent credit through AWSF. Offer valid only on {{validDate}}.",
            "ShopVehicles": "Shop vehicles you qualify for!",
            "NotificationDate_Time": "{{NotifyDate}} at {{NotifyTime}}",
            "AppointmentDate_Time": "{{apptDate}} at {{apptTime}}",
            "StoreDetails": "{{apptStoreName}}",
            "PendingTradeIn": "Your Offer is Pending",
            "PendingText": "We'll add your offer to your account and email it to you in the next 30 minutes.",
            "ExpiredTradeIn": "Get a New Trade-In Offer",
            "ExpiredText": "Your Certified Offer has expired.",
            "WBYCDisclaimer": "Offer good for 7 days or 500 additional miles, whichever comes first.",
            "NeedMoreInfoTitle": "We Need More Info",
            "NeedMoreInfoContent": "In order for us to accurately appraise your vehicle we need you to contact us. Call or chat with us today!",
            "LetsChat": "Let's Chat!",
            "NotificationChat": "Chats from Notification",
            "ServiceAdvisor": "Service Advisor",
            "Transportation": "Transportation",
            "AppleCalendar": "Apple Calendar",
            "GoogleCalendar": "Google Calendar",
            "Outlook": "Outlook",
            "Vehicle": "Vehicle"
        }
    }

    getAlerts() {
        this.noticationModalService.getAlerts().subscribe((res: any) => {
            if(res && res.Success && res.Data) {
                let response = res.Data;
                if(response.length !== 0) {
                    this.enableMarkAllAsRead = true;
                } else {
                    this.enableMarkAllAsRead = false;
                }
                const unreadCount = response.filter((x: any) => x.AlertStatus === 'UNREAD').length;
                // if(+this.getCookie('UnreadNotificationsCount') !== unreadCount) {
                    document.dispatchEvent(new CustomEvent('UNREAD_NOTIFICATIONS_COUNT', {
                        detail: unreadCount
                      }));
                // }
                
                document.cookie = "UnreadNotificationsCount=" + unreadCount + "; path=/;";
                response.forEach((item: any) => {
                    if(item.AlertType === 'prequal-alert') {
                        this.prequalData = item?.AlertDetails?.Prequal;
                        this.prequalData.AlertType = item?.AlertType;
                        this.prequalData.AlertKey = item?.AlertKey;
                        this.prequalData.AlertStatus = item?.AlertStatus;
                    } else if(item.AlertType === 'service-appointment-alert') {
                        const sData = item?.AlertDetails?.ServiceAppointment;
                        sData.AlertType = item?.AlertType;
                        sData.AlertKey = item?.AlertKey;
                        sData.AlertStatus = item?.AlertStatus;
                        this.serviceApptData.push(sData);
                    } else if(item.AlertType === 'sales-appointment-alert') {
                        const aData = item?.AlertDetails?.SalesAppointment;
                        aData.AlertType = item?.AlertType;
                        aData.AlertKey = item?.AlertKey;
                        aData.AlertStatus = item?.AlertStatus;
                        this.salesApptData.push(aData);
                    } else if(item.AlertType === 'wbyc-appraisal-value-alert') {
                        const tData = item?.AlertDetails?.TradeinAppraisal;
                        tData.AlertType = item?.AlertType;
                        tData.AlertKey = item?.AlertKey;
                        tData.AlertStatus = item?.AlertStatus;
                        this.tradeInData.push(tData);
                    }
                });
                this.data = {
                    Data: {
                        AppointmentNotifications: this.salesApptData,
                        PrequalNotification: this.prequalData,
                        ServiceAppointmentNotifications: this.serviceApptData,
                        TradeInNotification: this.tradeInData && this.tradeInData.length > 0 ?  this.tradeInData[0] : {}
                    }
                };
                this.getTradeInNotification();
                this.getUpcomingAppointmentDetails();
                this.getUpcomingServiceAppointmentDetails();
                this.getPrequalNotificationDetails();
                this.sortedData = this.allData.sort((a: any, b: any) => b.createdDate - a.createdDate);
            }
        });
    }

    controlTabNavigation(event: any) {
        var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
        let modalBox = document.getElementById('auth-modal-centents');
        if (modalBox) {
            const keyboardfocusableElements = Array.from(modalBox.querySelectorAll(focusableElementsString));
            const numberOfFocusableItems = keyboardfocusableElements.length;
            const firstFocusableElement = keyboardfocusableElements[0];
            const lastFocusableElement = keyboardfocusableElements[numberOfFocusableItems - 1];
            const focusedItem = document.activeElement;
            const focusedItemIndex = focusedItem ? keyboardfocusableElements.indexOf(focusedItem) : -1;

            if (event.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    (lastFocusableElement as HTMLElement).focus();
                } else {
                    (keyboardfocusableElements[focusedItemIndex - 1] as HTMLElement).focus();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    (firstFocusableElement as HTMLElement).focus();
                } else {
                    (keyboardfocusableElements[focusedItemIndex + 1] as HTMLElement).focus();
                }
            }
        }
        event.preventDefault();
    }

    markallAsRead(eventFrom?: any) {
        return new Promise((resolve, reject) => {  
            this.markallasRead = true;
            this.sortedData.forEach((item: any) => {
                item.alertStatus = 'READ';
            });
            this.sortedData.forEach((item:any)=>{
                let newAlertArray = 
                    {
                        AlertKey: item.alertKey,
                        AlertStatus: 'READ',
                        AlertType: item.alertType
                    };
                this.alertArray.push(newAlertArray);
            });
            console.log("Value of alertArray is ", this.alertArray);
            if(this.alertArray.length > 0){
                this.noticationModalService.updateAlerts(this.alertArray).subscribe((response:any) => {
                    console.log("Alert Updated", response);
                    if(response && response.Success){
                        document.cookie = "UnreadNotificationsCount=0; path=/;";
                        if(eventFrom === '') {
                            document.dispatchEvent(new CustomEvent('UNREAD_NOTIFICATIONS_COUNT', {
                                detail: 0
                            }));
                        }
                        resolve(true);
                    } else {
                        reject("Not Updating the alert");
                    }
                });
            }else{
                resolve(true);
            }
        });
    }

    
    closeNotificationModal(doAuth?: string) {
        const returnFlag = doAuth ? doAuth : '';
        this.showNotificationModal = false;
        this.onModalClose.emit(returnFlag);
     }
    getTradeInNotification() {
        this.tradeIn =
            this.data?.Data?.TradeInNotification;
        if (this.tradeIn && Object.keys(this.tradeIn).length > 0) {

            let appraisalStatus = this.tradeIn.AppraisalStatus;
            this.tradeInVin = this.tradeIn.Vin;
            this.hid = this.tradeIn.HyperionId;
            this.showWeOffer = true;
            if(this.tradeIn.CreatedDate) {
                const tradeInnotifDatefull = new Date(this.tradeIn.CreatedDate);
                this.tradeinTimeNotif = this.getNotificationTime(new Date(tradeInnotifDatefull));
            }
            this.tradeInText = this.cmsData.TradeIn.replace(
                '{{year}}',
                this.tradeIn.Year
            );

            this.tradeInText = this.tradeInText.replace(
                '{{makeModel}}',
                this.tradeIn.Make + ' ' + this.tradeIn.Model
            );
            this.weOffer = this.cmsData.WeOffer?.replace(
                '{{amount}}',
                this.tradeIn.CertifiedValue ? '$' + Math.round(parseInt(this.tradeIn.CertifiedValue) / 1000).toString() + 'K' : '0'
            );

            if (appraisalStatus === 'P') {
                this.tradeInStatus = false;
                this.tradeInPending = true;
                this.tradeInExpire = false;
                this.weOffer = this.cmsData.PendingTradeIn;
                this.tradeInText = this.cmsData.PendingText;
            } else if (appraisalStatus === 'E') {
                this.tradeInPending = false;
                this.tradeInStatus = false;
                this.tradeInExpire = true;
                this.weOffer = this.cmsData.ExpiredTradeIn;
                this.tradeInText = this.cmsData.ExpiredText;
            } else if (appraisalStatus === "X") {
                this.tradeInPending = false;
                this.tradeInStatus = false;
                this.tradeInExpire = false;
                this.tradeInReject = true;
                this.weOffer = this.cmsData.NeedMoreInfoTitle;
                this.tradeInText = this.cmsData.NeedMoreInfoContent;
            } else if (appraisalStatus === 'C') {
                this.tradeInStatus = true;
            } else if (appraisalStatus === null) {
                this.tradeInStatus = false;
                this.tradeInPending = true;
                this.tradeInExpire = false;
                this.weOffer = this.cmsData.PendingTradeIn;
                this.tradeInText = this.cmsData.PendingText;
            }
            this.allData.push({
                'createdDate': new Date(this.tradeIn.CreatedDate),
                'tradeInVin': this.tradeIn.Vin,
                'appraisalStatus': this.tradeIn.AppraisalStatus,
                'hid': this.tradeIn.HyperionId,
                'showWeOffer': this.showWeOffer,
                'tradeInStatus': this.tradeInStatus,
                'tradeInPending': this.tradeInPending,
                'tradeInExpire': this.tradeInExpire,
                'weOffer': this.weOffer,
                'tradeInText': this.tradeInText,
                'tradeinTimeNotif': this.tradeinTimeNotif,
                'alertStatus': this.tradeIn.AlertStatus,
                'alertType': this.tradeIn.AlertType,
                'alertKey': this.tradeIn.AlertKey
            })
        } else {
            this.showWeOffer = false;
        }
    }

    getNotificationTime(time: any) {
        const diff = new Date().getTime() - new Date(time).getTime();
        const days = Math.floor(diff / 86400000);
        const hours = Math.floor((diff % 86400000) / 3600000);
        const mins = Math.round(((diff % 86400000) % 3600000) / 60000);
        if (days > 0) {
            return `${days} ${days > 1 ? 'days' : 'day'} ago`
        } else if (hours > 0) {
            return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
        } else if (mins > 0) {
            return mins <= 1 ? 'Just Now' : `${mins} mins ago`
        }
        return ''
    }
    getUpcomingAppointmentDetails() {
        this.notify = this.data?.Data?.AppointmentNotifications;
    if(this.notify) {
        this.notify = this.notify.map((x: any) => {
            return {
                ...x,
                CreatedDateTime: this.convertCSTDateToLocalTime(x.CreatedDateTime),
            };
        });

        this.notify.forEach((item: any) => {

            if (item.AppointmentStatus === 'Scheduled') {
                const notifDatefull = new Date(item.CreatedDateTime);
                const notifdate = this._date.transform(notifDatefull, 'MMMM d, y') || '';
                const notifTime = this._date.transform(notifDatefull, 'hh:mm a');
                const appTimeOffDatefull = new Date(item.CreatedDateTime);
                const appTimeOff = this.getNotificationTime(new Date(appTimeOffDatefull));
                const date = new Date(item.ScheduledDateTime);
                const apptDate = this._date.transform(date, 'MM/dd/yyyy');
                const apptInnerDate = this._date.transform(date, 'MMMM d, y');
                const apptWeekday = this.weekdays[date.getDay()];
                const apptTime = this._date.transform(date, 'hh:mm a') || '';

                const storeName = item?.StoreName;
                const storePhone = item?.StoreInfo?.MainPhone;
                // const longitude = item.StoreInfo.Longitude;
                // const latitude = item.StoreInfo.Latitude;
                let hid = item.HyperionId;

                let imgURL = this.getStoreImageURL(hid);

                this.storeDetails = item.StoreInfo;

                this.notificationDate_Time = this.cmsData.NotificationDate_Time.replace(
                    '{{NotifyDate}}',
                    notifdate
                );
                this.notificationDate_Time = this.notificationDate_Time.replace(
                    '{{NotifyTime}}',
                    notifTime
                );

                this.apptDate_Time = this.cmsData.AppointmentDate_Time.replace(
                    '{{apptDate}}',
                    apptWeekday + ', ' + apptInnerDate
                );
                this.apptDate_Time = this.apptDate_Time.replace(
                    '{{apptTime}}',
                    apptTime
                );
                this.appStoreName = this.cmsData.StoreDetails.replace(
                    '{{apptStoreName}}',
                    storeName
                );

                this.apptInfoText = this.cmsData.ApptInfoText.replace(
                    '{{apptWeekday}}',
                    apptWeekday
                );
                this.apptInfoText = this.apptInfoText.replace('{{apptDate}}', apptDate || '');
                this.apptInfoText = this.apptInfoText.replace('{{apptTime}}', apptTime);
                this.apptInfoText = this.apptInfoText.replace(
                    '{{apptStoreName}}',
                    storeName
                );



                this.allData.push({
                    createdDate: new Date(item.CreatedDate),
                    isAppt: true,
                    appTimeOff: appTimeOff,
                    notifyDate: this.notificationDate_Time,
                    apptInfoText: this.apptInfoText,
                    apptDate: this.apptDate_Time,
                    SoughtVehicleVins: item?.SoughtVehicleVins,
                    appStore: this.appStoreName,
                    storeDetails: this.storeDetails,
                    storePhone: storePhone,
                    storeImgSrc: imgURL,
                    apptId: item?.AppointmentId?.toString(),
                    appointmentdate: item.ScheduledDateTime,
                    hyperionId: item.HyperionId,
                    opptId: item?.LeadId?.toString(),
                    alertStatus: item.AlertStatus,
                    alertType: item.AlertType,
                    alertKey: item.AlertKey
                });
                this.myUpcomingAppointmentDetails.push(this.notifyData);
            }
        });
    }
    }
    convertCSTDateToLocalTime(cstDate: string): string {
        try {
            return cstDate.toString();
        } catch (ex) {
            console.error('error converting date');
            return cstDate;
        }
    }
    getStoreImageURL(hid: string) {
        const imgurl = this.storeDetail;
        let imgSRC = imgurl?.filter((x: any) => x.HyperionValue === hid.toString());

        const storeImageSrc = imgSRC[0]?.ImageURL;
        return storeImageSrc;
    }
    getUpcomingServiceAppointmentDetails() {
        this.serviceNotify = this.data?.Data?.ServiceAppointmentNotifications || [];
        this.serviceNotify?.forEach((item: any) => {
            let serviceNotificationDate_Time = '';
            let serviceStoreDetails = '';
            let serviceApptDate_Time = '';
            let serviceAppStoreName = '';
            let serviceApptInfoText = '';
            let appointmentStartDate: any = '';
            let notifdate;
            let notifTime;
            let apptDate;
            let apptInnerDate;
            let apptWeekday;
            let apptTime;
            let storeName;
            let storePhone;
            let longitude;
            let latitude;
            let hid;
            let imgURL;
            if (item?.CreatedDateTime) {
                const notifDatefull = new Date(item?.CreatedDateTime);
                notifdate = this._date.transform(notifDatefull, 'MMMM d, y');
                notifTime = this._date.transform(notifDatefull, 'hh:mm a');

            }
            const appTimeOffDatefull = new Date(item.CreatedDateTime);
            const appTimeOff = this.getNotificationTime(new Date(appTimeOffDatefull));
            if (item?.ScheduledDateTime) {
                const date = new Date(`${item.ScheduledDateTime}`);
                appointmentStartDate = date;
                apptDate = this._date.transform(date, 'MM/dd/yyyy');
                apptInnerDate = this._date.transform(date, 'MMMM d, y');
                apptWeekday = this.weekdays[date.getDay()];
                apptTime = this._date.transform(date, 'hh:mm a');
            }
            // if (item?.StoreInfo) {
            //     storeName = item.StoreInfo.Name;
            //     storePhone = item.StoreInfo.MainPhone;
            //     longitude = item.StoreInfo.Longitude;
            //     latitude = item.StoreInfo.Latitude;
            //     hid = item.StoreInfo.HyperionId;
            //     if (hid) {
            //         imgURL = this.getStoreImageURL(hid);
            //     }
            //     serviceStoreDetails = item.StoreInfo;
            // }
            storeName = item?.StoreName;
            hid = item?.HyperionId;
            if (hid) {
                imgURL = this.getStoreImageURL(hid);
            }
            if (notifdate) {

                serviceNotificationDate_Time = this.cmsData.NotificationDate_Time.replace(
                    '{{NotifyDate}}',
                    notifdate
                );
            }
            if (notifTime) {
                serviceNotificationDate_Time = serviceNotificationDate_Time.replace(
                    '{{NotifyTime}}',
                    notifTime
                );
            }

            serviceApptDate_Time = this.cmsData.AppointmentDate_Time.replace(
                '{{apptDate}}',
                apptWeekday + ', ' + apptInnerDate
            );
            serviceApptDate_Time = serviceApptDate_Time.replace(
                '{{apptTime}}',
                apptTime || ''
            );
            serviceAppStoreName = this.cmsData.StoreDetails.replace(
                '{{apptStoreName}}',
                storeName
            );

            serviceApptInfoText = this.cmsData.ApptInfoText.replace(
                '{{apptWeekday}}',
                apptWeekday || ''
            );
            serviceApptInfoText = serviceApptInfoText.replace('{{apptDate}}', apptDate || '');
            serviceApptInfoText = serviceApptInfoText.replace('{{apptTime}}', apptTime || '');
            serviceApptInfoText = serviceApptInfoText.replace(
                '{{apptStoreName}}',
                storeName
            );

            const serviceNotifyData = {
                Vin: item.Vin,
                createdDate: new Date(item.CreatedDate),
                appTimeOff: appTimeOff,
                notifyDate: serviceNotificationDate_Time,
                apptInfoText: serviceApptInfoText,
                apptDate: serviceApptDate_Time,
                appStore: serviceAppStoreName,
                storeDetails: serviceStoreDetails,
                SoughtVehicleVins: item?.SoughtVehicleVins,
                storePhone: storePhone,
                storeImgSrc: imgURL ? imgURL : '',
                apptId: item?.LoopAppointmentId?.toString(),
                appointmentdate: item?.AppointmentDate,
                hyperionId: item?.HyperionId,
                serviceAdvisorName: item?.ServiceAdvisorName,
                appointmentUrl: item?.AppointmentUrl,
                serviceApptStartDate: appointmentStartDate,
                vehicleInfo: `${item?.Year} ${item?.Make} ${item?.Model} `,
                isServiceAppt: true,
                alertStatus: item.AlertStatus,
                alertType: item.AlertType,
                alertKey: item.AlertKey
            };
            this.allData.push(serviceNotifyData);
        });
    }
    getPrequalNotificationDetails() {
        let prequal = this.data?.Data?.PrequalNotification;
        let showPrequalContainer = false;
        if (prequal && Object.keys(prequal).length > 0) {
            showPrequalContainer = true;

            const preQualnotifDatefull = new Date(prequal.CreatedDate);
            const preQualTimeDatefull = new Date(prequal.CreatedDate);
            const preQualTime = this.getNotificationTime(new Date(preQualTimeDatefull));
            const prequalnotifdate = this._date.transform(
                preQualnotifDatefull,
                'MMMM d, y'
            );
            const prequalnotifTime = this._date.transform(
                preQualnotifDatefull,
                'hh:mm a'
            );

            let preQualnotificationDate_Time =
                this.cmsData.NotificationDate_Time.replace(
                    '{{NotifyDate}}',
                    prequalnotifdate || ''
                );
            preQualnotificationDate_Time =
                preQualnotificationDate_Time.replace(
                    '{{NotifyTime}}',
                    prequalnotifTime || ''
                );

            let preQualifiedAmt = prequal.Amount
                ? prequal.Amount
                : 0;
                this.preQualifiedAmt = preQualifiedAmt;

            let tradeInText = this.cmsData.TradeIn.replace(
                '{{year}}',
                prequal.Year
            );
            this.allData.push({
                preQualTime,
                createdDate: new Date(prequal.CreatedDate),
                showPrequalContainer: showPrequalContainer,
                preQualnotifDatefull,
                prequalnotifdate,
                prequalnotifTime,
                preQualnotificationDate_Time,
                appStore: this.appStoreName,
                preQualifiedAmt,
                tradeInText,
                alertStatus: prequal.AlertStatus,
                alertType: prequal.AlertType,
                alertKey: prequal.AlertKey
            });
            this.myUpcomingAppointmentDetails.push(this.notifyData);
        } else {
            showPrequalContainer = false;
        }
    }

    openStoreAppt(item : any) {
        if(item){
            let reqBody = [
                {
                "AlertKey": item.alertKey,
                "AlertStatus": 'READ',
                "AlertType": item.alertType
                }
            ];
            console.log("openStoreAppt ReqBody is - ", reqBody);
            if(item.alertStatus === 'UNREAD'){
                this.noticationModalService.updateAlerts(reqBody).subscribe((response:any) => {
                    console.log("Alert Updated", response);
                    if(response && response.Success){
                        this.updNotificationCount();
                        if(item?.SoughtVehicleVins && item?.SoughtVehicleVins.length > 0)
                        {
                            window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/my-vehicle?vin=${item.SoughtVehicleVins[item.SoughtVehicleVins.length-1]}`;
                        } else {
                            this.navigateToNotiications();
                        }
                    }
                });
            } else {
                if(item?.SoughtVehicleVins && item?.SoughtVehicleVins.length > 0)
                {
                    window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/my-vehicle?vin=${item.SoughtVehicleVins[item.SoughtVehicleVins.length-1]}`;
                } else {
                    this.navigateToNotiications();
                }
            }
        }      
    }

    openTradeIn(item : any) {
        const pendingDealsCount = this.getCookie('PendingDealsCount');
        console.log("pendingDealsCount value is ", pendingDealsCount);
        if(+pendingDealsCount > 0) {
            if(item){
                let reqBody = [
                    {
                    "AlertKey": item.alertKey,
                    "AlertStatus": 'READ',
                    "AlertType": item.alertType
                    }
                ];
                console.log("openTradeIn ReqBody is - ", reqBody);
                if(item.alertStatus === 'UNREAD'){
                    this.noticationModalService.updateAlerts(reqBody).subscribe((response:any) => {
                        console.log("Alert Updated", response);
                        if(response && response.Success){
                            this.updNotificationCount();
                        }
                    });
                }
            }
            window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/my-vehicle`;
        } else {
            this.navigateToNotiications();
        }
        
        
    }

    navigateToNotiications(eventFrom?: any) {
        this.markallAsRead(eventFrom).then((value) => {
            console.log("Value is ", value);
            window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/notifications`;
          })
          .catch(error => {
            console.error('Error in getMyValue:', error);
          });
        // window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/notifications`;
    }

    openPrequal(item : any) {
        if(item){
            let reqBody = [
                {
                "AlertKey": item.alertKey,
                "AlertStatus":'READ',
                "AlertType": item.alertType
                }
            ];
            console.log("openPrequal ReqBody is - ", reqBody);
            if(item.alertStatus === 'UNREAD'){
                this.noticationModalService.updateAlerts(reqBody).subscribe((response:any) => {
                    console.log("Alert Updated", response);
                    if(response && response.Success){
                        this.updNotificationCount();
                    }
                });
            }
        }
        window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/notifications`;
    }

    openSerAppt(vin: string, item: any) {
        if(item){
            let reqBody = [
                {
                "AlertKey": item.alertKey,
                "AlertStatus": 'READ',
                "AlertType": item.alertType
                }
            ];
            console.log("openSerAppt ReqBody is - ", reqBody);
            if(item.alertStatus === 'UNREAD'){
                this.noticationModalService.updateAlerts(reqBody).subscribe((response:any) => {
                    console.log("Alert Updated", response);
                    if(response && response.Success){
                        this.updNotificationCount();
                    }
                });
            }            
        }
        window.location.href = `${window.location.protocol}//${window.location.hostname}/my-account/my-vehicle?owned=true`;
    }

    @HostListener('mouseup', ['$event']) onMouseUp(event: any) {
        // this.dragging = false;
        // this.tabs.classList.remove('dragging');
        // event.preventDefault();
        const container : any= document.getElementById('auth-modal-centents');
        if (!container.contains(event.target)) {
          this.showNotificationModal = false;
        }
      }

      @HostListener('window:keydown', ['$event'])
      onKeyDownHandler(event: KeyboardEvent) {
        
        if (event.key === 'Escape' || event.keyCode === 27) {
            this.showNotificationModal = false;
        }
      }

      getCookie(name: string) {
        const cookies: Array<string> = document.cookie.split(';');
        const cookiesLength: number = cookies.length;
        let cookieName = `${name}=`;
        let cookie: string;
    
        for (let i: number = 0; i < cookiesLength; i += 1) {
          cookie = cookies[i].replace(/^\s+/g, '');
          if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
          }
        }
        return '0';
      }

      isUserLoggedIn(): boolean {
        const inputValue = (<HTMLInputElement>document.getElementById('ANUserId')) ? (<HTMLInputElement>document.getElementById('ANUserId')).value : '';
        return inputValue !== '';
      }

      isAlertsEnabled(): boolean {
        const inputValue = (<HTMLInputElement>document.getElementById('EnableAlerts')) ? (<HTMLInputElement>document.getElementById('EnableAlerts')).value : '';
        return inputValue === 'true';
      }

      updNotificationCount(){
        const getNotifCount = this.getCookie('UnreadNotificationsCount');
        const updateCount = parseInt(getNotifCount) - 1;
        this.deleteCookie("UnreadNotificationsCount");
        document.cookie = "UnreadNotificationsCount=" + updateCount + "; path=/;HttpOnly; Secure; SameSite=Lax;";
        // document.dispatchEvent(new CustomEvent('UNREAD_NOTIFICATIONS_COUNT', {
        //     detail: updateCount
        // }));

        console.log("getNotifCount value is ", getNotifCount);
        console.log("updateCount value is ", updateCount);
        console.log("document.cookie value is ", document.cookie);
      }

      deleteCookie(cookieName:any) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
}
