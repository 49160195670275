<div #modal aria-hidden="true" class="new-modal" role="dialog" *ngIf="showAddVehicleModal">

  <div class="an-vehicle-modal" id="container" [ngClass]="{'max-veh-modal': vehicles.length ===1}">
    <div class="an-modal-close" (click)="closeModal('askmelater')">

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24Z"
          fill="#EAEAEA" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M15.7551 14.5512C16.087 14.8833 16.087 15.4199 15.7551 15.752C15.5895 15.9175 15.3722 16.0007 15.1547 16.0007C14.9373 16.0007 14.72 15.9175 14.5544 15.752L12.003 13.2004L9.45157 15.752C9.28602 15.9175 9.06866 16.0007 8.85132 16.0007C8.63383 16.0007 8.41646 15.9175 8.2509 15.752C7.91897 15.4199 7.91897 14.8833 8.2509 14.5512L10.8025 11.9999L8.2509 9.44842C7.91897 9.11634 7.91897 8.5798 8.2509 8.24773C8.58294 7.9158 9.11949 7.9158 9.45157 8.24773L12.003 10.7993L14.5544 8.24773C14.8865 7.9158 15.423 7.9158 15.7551 8.24773C16.087 8.57981 16.087 9.11635 15.7551 9.44842L13.2035 11.9999L15.7551 14.5512Z"
          fill="#949494" />
      </svg>
    </div>
    <ng-container [formGroup]="form">

      <div class="an-vehicles-tab ">
        <div class="an-vehicles__title">
          {{data.header}}
        </div>
        <div class="an-vehicles__content">
          {{data.subHeader}}
        </div>
        <div class="an-vehicles-body chev-wrapper" *ngIf="(!isFinishAddingFlow && vehicles.length > 1) || ismobile">
          <div id="leftArr-chev" class="left-arrow paddle left-side disable" (click)="scrollTabLeft()">
            <div class="left-arr">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.29489 0.227812C7.0214 -0.0759373 6.57897 -0.0759373 6.30549 0.227812L0.705114 6.45006C0.431629 6.75381 0.431629 7.24619 0.705114 7.54994L6.30549 13.7722C6.57897 14.0759 7.0214 14.0759 7.29489 13.7722C7.56837 13.4684 7.56837 12.9761 7.29489 12.6723L2.19015 7L7.29489 1.3277C7.56837 1.02395 7.56837 0.531561 7.29489 0.227812Z"
                  fill="#0A3453" />
              </svg>
            </div>
          </div>
          <div class="an-vehicles ">

            <div *ngFor="let vehicle of vehicles; let pos = index">
              <div class="an-vehicle-details" [ngClass]="{'ml5': pos===0}"
                [id]="'an-vehicle-details-' + vehicle.VehicleId" [ngStyle]="{'margin-left: 15px;': pos===0}">
                <div class="an-vehicle-image" *ngIf="isImageURLValid(vehicle)">
                  <img [id]="'an-image-' + vehicle.VehicleId" class="an-vehicle-imageurl" [src]="vehicle.ImageUrl" />
                </div>
                <div class="an-vehicle-image" *ngIf="!isImageURLValid(vehicle)"
                  [ngClass]="{'an-vehicle-image-disable':!isImageURLValid(vehicle)}">
                  <svg width="100%" height="100%" viewBox="0 0 252 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_11394_83165" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                      width="252" height="113">
                      <path
                        d="M52.9845 28.0837H52.4628C51.9354 28.0833 51.4156 27.9579 50.9461 27.7178C50.4765 27.4776 50.0706 27.1295 49.7615 26.7021C49.4525 26.2747 49.2493 25.7801 49.1684 25.2589C49.0875 24.7378 49.1313 24.2049 49.2962 23.7039C49.9635 21.6535 51.0919 19.5788 52.936 19.2634C56.867 18.6082 67.6649 21.8719 67.6649 24.1164C67.6649 26.3609 67.6649 27.3801 66.3546 27.3801H58.1773L51.6378 34.5868L51.4923 34.4655C51.1553 34.1819 50.8845 33.8281 50.6987 33.4289C50.5129 33.0296 50.4166 32.5946 50.4166 32.1542C50.4166 31.7139 50.5129 31.2788 50.6987 30.8796C50.8845 30.4803 51.1553 30.1265 51.4923 29.843C52.0915 29.3511 52.597 28.7552 52.9845 28.0837Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M126.902 88.9332H235.489C235.489 88.9332 251.188 78.4628 247.912 50.9826C244.637 23.5024 226.984 41.8347 220.444 60.1427H172.035C172.035 60.1427 161.565 39.214 144.567 41.8347C127.57 44.4553 126.902 88.9332 126.902 88.9332Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M153.584 83.4827C153.738 76.2044 151.641 69.0562 147.579 63.0151V55.3716H134.488C123.932 55.3716 115.379 67.953 115.379 83.4827C115.379 99.0123 123.932 111.618 134.488 111.618H147.579V103.975C151.646 97.9266 153.743 90.7691 153.584 83.4827Z"
                        fill="#263238"></path>
                      <path
                        d="M147.577 111.594C158.124 111.594 166.674 99.008 166.674 83.4827C166.674 67.9573 158.124 55.3716 147.577 55.3716C137.03 55.3716 128.48 67.9573 128.48 83.4827C128.48 99.008 137.03 111.594 147.577 111.594Z"
                        fill="#263238"></path>
                      <mask id="mask1_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="128" y="55"
                        width="39" height="57">
                        <path
                          d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask1_11394_83165)">
                        <path opacity="0.2"
                          d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                          fill="white"></path>
                      </g>
                      <path
                        d="M35.5843 55.3716H23.2212C13.2482 55.3716 5.16797 67.953 5.16797 83.4827C5.16797 99.0123 13.2482 111.618 23.2212 111.618H35.5843C45.5572 111.618 53.6375 99.0366 53.6375 83.5069C53.6375 67.9773 45.5572 55.3716 35.5843 55.3716Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                        fill="white"></path>
                      <path
                        d="M243.024 83.4827C243.173 76.4698 241.872 69.5018 239.203 63.0151V55.3716H227.64C220.919 55.3716 215.508 67.953 215.508 83.4827C215.508 99.0123 220.943 111.594 227.64 111.594H239.203V103.95C241.872 97.4635 243.173 90.4955 243.024 83.4827Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                        fill="#263238"></path>
                      <mask id="mask2_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="227" y="55"
                        width="25" height="57">
                        <path
                          d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask2_11394_83165)">
                        <path opacity="0.2"
                          d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                          fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </g>
                      <path
                        d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                        fill="white"></path>
                      <path
                        d="M131.474 90.3511C131.474 67.9544 139.263 49.792 148.86 49.792C158.457 49.792 166.246 67.9908 166.246 90.3511C166.246 91.5643 166.246 92.7776 166.173 93.9908H228.11C228.11 92.7776 228.049 91.5643 228.049 90.3511C228.049 67.9423 233.727 49.7434 240.764 49.7434C245.435 49.7434 249.512 57.8601 251.683 69.9199V36.8829C251.686 35.3748 251.159 33.9137 250.194 32.7542C249.23 31.5947 247.889 30.8103 246.406 30.5376L246.102 30.4769C241.82 29.6155 234.091 21.4625 222.068 11.162C210.045 0.861426 199.319 0 199.319 0H90.8544C88.8048 0.000624519 86.8128 0.678833 85.1885 1.92907L48.2085 30.4769C48.2085 30.4769 15.0988 36.9072 7.81931 44.211C0.539779 51.5148 1.37693 93.9544 1.37693 93.9544H131.571C131.498 92.8018 131.474 91.5886 131.474 90.3511Z"
                        fill="white"></path>
                      <path
                        d="M8.73478 54.471C7.94599 53.7032 6.57408 53.1747 5.5575 52.8655C4.81654 52.6401 4.91106 49.9452 4.91106 49.9452C4.758 50.5829 4.19755 52.7992 3.76945 54.9395C3.38365 56.7652 3.19141 58.3285 3.19141 58.3285H10.7301C10.7301 58.3285 9.95016 55.654 8.73478 54.471Z"
                        fill="#E61464"></path>
                      <path
                        d="M93.135 55.7083C91.5413 57.3036 89.6905 60.3426 89.6905 60.3426C89.6905 60.3426 111.674 60.3426 124.605 58.4028C129.069 55.653 134.777 52.0855 137.307 45.5473C137.881 44.0623 138.297 41.592 138.297 41.592C127.737 45.3064 100.709 48.1267 93.135 55.7083Z"
                        fill="#CC1259"></path>
                      <path
                        d="M172.683 0H90.849C88.7993 0.000624519 86.8074 0.678833 85.1831 1.92907L48.2031 30.4769H152.931L172.683 0Z"
                        fill="white"></path>
                      <path
                        d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                        fill="#263238"></path>
                      <mask id="mask3_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="152" y="3"
                        width="72" height="28">
                        <path
                          d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask3_11394_83165)">
                        <path opacity="0.2"
                          d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                          fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </g>
                      <path
                        d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M82.6842 3.86023L48.1914 30.479H152.932L170.172 3.86023H82.6842Z" fill="#263238"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M15.5742 52.8047V61.8192L22.0045 66.5387H73.9439L85.9672 61.3945V52.8047H15.5742Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M110.868 75.1103H3.98018L2.54855 63.6572C1.89339 69.9539 1.60221 76.6633 1.46875 82.1714L1.84486 83.7001L3.98018 79.4052H13.1524L7.42582 84.1247H92.3536L89.3084 79.4052H110.807L116.813 82.8387H121.108V61.0001L110.868 75.1103Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M194.81 30.4792H198.389C198.013 25.2136 196.509 13.6149 190.612 3.86035H186.973C189.096 8.34939 193.997 19.9117 194.81 30.4792Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M117.413 11.0902L118.723 14.7299C117.111 16.2711 116.17 18.3847 116.103 20.6142V30.5265H108.059L114.792 11.1144L117.413 11.0902ZM79.8022 15.9432C74.2819 19.3039 65.9347 26.7169 61.8945 30.4294H88.7802L102.696 9.07617L93.5362 9.7313C88.7043 11.2091 84.087 13.3138 79.8022 15.9917V15.9432ZM135.406 13.3468L132.13 12.6916H127.556L126.246 14.002C126.246 14.002 129.521 14.3295 129.885 16.295C130.111 18.2483 130.22 20.2131 130.213 22.1793H132.506C132.506 22.1793 132.506 18.5395 134.144 16.9502C135.07 16.0145 136.054 15.1392 137.092 14.3295L135.406 13.3468Z"
                        fill="#525252" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M175.309 28.0837H174.775C174.248 28.0833 173.728 27.9579 173.259 27.7178C172.789 27.4776 172.383 27.1295 172.074 26.7021C171.765 26.2747 171.562 25.7801 171.481 25.2589C171.4 24.7378 171.444 24.2049 171.609 23.7039C172.276 21.6535 173.404 19.5788 175.309 19.2634C179.228 18.6082 190.026 21.8719 190.026 24.1164C190.026 26.3609 190.026 27.3801 188.716 27.3801H180.538L174.035 34.6353L173.89 34.514C173.553 34.2304 173.282 33.8766 173.096 33.4774C172.91 33.0781 172.814 32.6431 172.814 32.2028C172.814 31.7624 172.91 31.3274 173.096 30.9281C173.282 30.5289 173.553 30.1751 173.89 29.8915C174.461 29.3726 174.941 28.7615 175.309 28.0837Z"
                        fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M173.862 34.5169L174.008 34.6382L180.547 27.4315H188.724C189.804 27.4315 189.938 26.7521 190.023 25.2234H171.496C171.605 26.0178 171.998 26.7457 172.603 27.2723C173.207 27.7988 173.982 28.0882 174.784 28.0867H175.318C174.938 28.7672 174.446 29.3785 173.862 29.8944C173.525 30.178 173.254 30.5318 173.068 30.931C172.883 31.3303 172.786 31.7653 172.786 32.2057C172.786 32.646 172.883 33.081 173.068 33.4803C173.254 33.8795 173.525 34.2333 173.862 34.5169Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path d="M194.056 38.373H186.691V39.9503H194.056V38.373Z" fill="#263238" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M227.177 38.373H219.812V39.9503H227.177V38.373Z" fill="#263238" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M221.348 87.8041H166.206C166.206 88.6129 166.206 89.4622 166.206 90.3519C166.206 91.5652 166.206 92.7784 166.133 93.9917H228.069C228.069 92.7784 228.009 91.5652 228.009 90.3519C228.009 90.267 228.009 90.1821 228.009 90.0971L221.348 87.8041Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M131.472 90.3517C131.472 89.5024 131.472 88.6531 131.472 87.8038H1.38672C1.38672 91.5892 1.38672 94.0036 1.38672 94.0036H131.569C131.496 92.8025 131.472 91.5892 131.472 90.3517Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M249.355 35.1875C248.701 35.1875 248.057 35.3578 247.488 35.6817C246.919 36.0056 246.445 36.472 246.11 37.035C245.776 37.598 245.594 38.2383 245.583 38.8928C245.571 39.5474 245.73 40.1938 246.043 40.7685L251.661 51.069V36.886C251.654 36.3237 251.572 35.7647 251.418 35.2239L249.355 35.1875Z"
                        fill="#CC1259"></path>
                      <path d="M198.859 35.9357C198.859 35.9357 210.628 52.9212 198.859 81.7239" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M122.01 70.6865H96.1471H43.1284H2.39453V87.6341V93.963H131.708C131.961 88.408 131.665 85.2564 132.355 79.7385C132.994 74.6282 134.941 66.8071 134.941 66.8071L122.01 70.6865Z"
                        fill="#E7E7E7" fill-opacity="0.93"></path>
                    </mask>
                    <g mask="url(#mask0_11394_83165)">
                      <path d="M-60.4609 -47.4076H318.199V144.023H-60.4609V-47.4076Z" fill="#F1F1F1"></path>
                    </g>
                    <path
                      d="M8.73869 54.471C7.9499 53.7032 6.57798 53.1747 5.56141 52.8655C4.82045 52.6401 4.91496 49.9452 4.91496 49.9452C4.76191 50.5829 4.20146 52.7992 3.77336 54.9395C3.38756 56.7652 3.19531 58.3285 3.19531 58.3285H10.734C10.734 58.3285 9.95406 55.654 8.73869 54.471Z"
                      fill="#DADADA"></path>
                    <path
                      d="M93.1389 55.7083C91.5452 57.3036 89.6944 60.3426 89.6944 60.3426C89.6944 60.3426 111.678 60.3426 124.609 58.4028C129.073 55.653 134.781 52.0855 137.311 45.5473C137.885 44.0623 138.301 41.592 138.301 41.592C127.74 45.3064 100.713 48.1267 93.1389 55.7083Z"
                      fill="#DADADA"></path>
                    <path d="M15.5742 52.8046V61.819L22.0045 66.5386H73.9439L85.9672 61.3944V52.8046H15.5742Z"
                      fill="#D8D8D8"></path>
                    <path
                      d="M249.359 35.1874C248.705 35.1873 248.061 35.3576 247.492 35.6816C246.923 36.0055 246.448 36.4719 246.114 37.0349C245.78 37.5979 245.598 38.2381 245.587 38.8927C245.575 39.5473 245.734 40.1937 246.047 40.7684L251.665 51.0689V36.8859C251.658 36.3235 251.576 35.7646 251.422 35.2238L249.359 35.1874Z"
                      fill="#DADADA"></path>
                    <path d="M82.6881 3.86011L48.1953 30.4789H153.475L170.877 3.86011H82.6881Z" fill="#D9D9D9"
                      stroke="#D9D9D9" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                      fill="#DEDCDC"></path>
                    <path
                      d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                      fill="#DEDCDC"></path>
                    <path d="M195.857 41.9735H188.492V43.5507H195.857V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                      stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path d="M228.982 41.9735H221.617V43.5507H228.982V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                      stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path
                      d="M194.814 30.4792H198.393C198.017 25.2136 196.513 13.6149 190.616 3.86035H186.977C189.1 8.34939 194.001 19.9117 194.814 30.4792Z"
                      fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.850212" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path
                      d="M112.673 78.7109H5.78486L4.35323 67.2577C3.69808 73.5545 3.4069 80.2638 3.27344 85.772L3.64954 87.3007L5.78486 83.0058H14.9571L9.2305 87.7253H94.1583L91.1131 83.0058H112.612L118.618 86.4393H122.912V64.6007L112.673 78.7109Z"
                      fill="#D8D8D8" stroke="#D8D8D8" stroke-width="0.850212" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="an-vehicle-body tab-spreadout-veh-body">
                  <div class="an-vehicle-ymm">
                    <span class="an-vehicle-year">
                      {{vehicle.Year}}
                    </span>
                    <span class="an-vehicle-mm">
                      {{vehicle.Make}} {{vehicle.Model}}
                    </span>
                  </div>
                  <div class="an-vehicle-trim" [id]="'an-model-' + vehicle.VehicleId" *ngIf="vehicle.Trim.length === 0">
                    <an-select [enableCustomcss]="true" [items]="getVehicleModel(vehicle)" [id]="vehicle.VehicleId"
                      label="Model *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anaddVehModel">
                    </an-select>
                  </div>
                  <div class="an-vehicle-trim" [id]="'an-trim-' + vehicle.VehicleId">
                    <an-select [enableCustomcss]="true" [items]="trimValue[vehicle.VehicleId]" [id]="vehicle.VehicleId"
                      label="Trim *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anSelect" [idValue]="getId(vehicle)">
                    </an-select>
                  </div>

                  <!-- For Multiple vehicles -->
                  <div class="an-vehicle-button-group body-button" *ngIf="vehicles.length > 1"
                    [id]="'an-trim-button-' + vehicle.VehicleId">
                    <div [id]="'an-vehicle-div-'+ pos">
                      <button class="an-vehicle-button" [id]="'an-vehicle-' + vehicle.VehicleId"
                        (click)="addVehicle(pos,vehicle)">
                        <span [id]="'an-vehicle-image-'+pos" class="dnone">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M6.99382e-07 8C8.37707e-07 6.41775 0.469193 4.87103 1.34824 3.55544C2.2273 2.23984 3.47672 1.21446 4.93853 0.608963C6.40034 0.00346195 8.00888 -0.154965 9.56072 0.153717C11.1126 0.462399 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16L8 14.8C9.34491 14.8 10.6596 14.4012 11.7779 13.654C12.8961 12.9068 13.7677 11.8448 14.2824 10.6022C14.7971 9.35971 14.9317 7.99246 14.6693 6.67339C14.407 5.35431 13.7593 4.14267 12.8083 3.19167C11.8573 2.24068 10.6457 1.59304 9.32662 1.33066C8.00754 1.06828 6.64029 1.20294 5.39775 1.71762C4.15522 2.23229 3.0932 3.10387 2.34601 4.22212C1.59881 5.34038 1.2 6.65509 1.2 8L6.99382e-07 8Z"
                              fill="white" />
                          </svg>
                        </span>
                        {{data.addThisVehicle}}
                      </button>

                    </div>
                    <div>
                      <button class="dnone" [id]="'an-vehicle-added-'+pos">
                        <span style="margin-right: 8px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M8.33203 0.87085C4.19637 0.87085 0.832031 4.23518 0.832031 8.37085C0.832031 12.5065 4.19637 15.8708 8.33203 15.8708C12.4677 15.8708 15.832 12.5065 15.832 8.37085C15.832 4.23518 12.4677 0.87085 8.33203 0.87085Z"
                              fill="#BCBCBC" />
                            <path
                              d="M12.1335 6.7814L8.07092 10.8438C7.94904 10.9657 7.78905 11.027 7.62906 11.027C7.46907 11.027 7.30908 10.9657 7.1872 10.8438L5.15599 8.81261C4.91154 8.56828 4.91154 8.17323 5.15599 7.9289C5.40032 7.68446 5.79526 7.68446 6.0397 7.9289L7.62906 9.51826L11.2497 5.89769C11.4941 5.65324 11.889 5.65324 12.1335 5.89769C12.3778 6.14202 12.3778 6.53696 12.1335 6.7814Z"
                              fill="#FAFAFA" />
                          </svg>
                        </span>
                        Vehicle Added
                      </button>
                    </div>
                    <div (click)="removeVehicle(pos,vehicle)" class="an-vehicle-remove"
                      [id]="'an-vehicle-remove-' + vehicle.VehicleId" [ngClass]="{'disableRemove': isRemoveDiasble}">

                      {{data.remove}}
                    </div>
                  </div>

                  <!-- For Single Vehicle -->
                  <div class="an-vehicle-button-group only-mobile body-button" *ngIf="vehicles.length === 1"
                    [id]="'an-trim-button-' + vehicle.VehicleId">
                    <div (click)="removeVehicle(pos,vehicle)" class="an-vehicle-remove"
                      [id]="'an-vehicle-remove-' + vehicle.VehicleId" [ngClass]="{'disableRemove': isRemoveDiasble}">

                      {{data.remove}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div id="rightArr-chev" class="right-arrow paddle right-side disable" (click)="scrollTabRight()">
            <div class="right-arr">

              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.705114 0.227812C0.978599 -0.0759373 1.42103 -0.0759373 1.69451 0.227812L7.29489 6.45006C7.56837 6.75381 7.56837 7.24619 7.29489 7.54994L1.69451 13.7722C1.42103 14.0759 0.978599 14.0759 0.705114 13.7722C0.431629 13.4684 0.431629 12.9761 0.705114 12.6723L5.80985 7L0.705114 1.3277C0.431629 1.02395 0.431629 0.531561 0.705114 0.227812Z"
                  fill="#0A3453" />
              </svg>
            </div>
          </div>
        </div>
        <div class="an-vehicles-body" *ngIf="!isFinishAddingFlow && vehicles.length === 1 && !ismobile">

          <div class="an-vehicles ">

            <div *ngFor="let vehicle of vehicles; let pos = index">
              <div class="an-vehicle-details spreadout-veh" [id]="'an-vehicle-details-' + vehicle.VehicleId"
                [ngStyle]="{'margin-left: 15px;': pos===0}">
                <div class="spread-veh">
                  <div class="an-vehicle-image" *ngIf="isImageURLValid(vehicle)">
                    <img [id]="'an-image-' + vehicle.VehicleId" class="an-vehicle-imageurl" [src]="vehicle.ImageUrl" />
                  </div>
                  <div class="an-vehicle-image" *ngIf="!isImageURLValid(vehicle)"
                    [ngClass]="{'an-vehicle-image-disable':!isImageURLValid(vehicle)}">
                    <svg width="100%" height="100%" viewBox="0 0 252 113" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_11394_83165" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                        width="252" height="113">
                        <path
                          d="M52.9845 28.0837H52.4628C51.9354 28.0833 51.4156 27.9579 50.9461 27.7178C50.4765 27.4776 50.0706 27.1295 49.7615 26.7021C49.4525 26.2747 49.2493 25.7801 49.1684 25.2589C49.0875 24.7378 49.1313 24.2049 49.2962 23.7039C49.9635 21.6535 51.0919 19.5788 52.936 19.2634C56.867 18.6082 67.6649 21.8719 67.6649 24.1164C67.6649 26.3609 67.6649 27.3801 66.3546 27.3801H58.1773L51.6378 34.5868L51.4923 34.4655C51.1553 34.1819 50.8845 33.8281 50.6987 33.4289C50.5129 33.0296 50.4166 32.5946 50.4166 32.1542C50.4166 31.7139 50.5129 31.2788 50.6987 30.8796C50.8845 30.4803 51.1553 30.1265 51.4923 29.843C52.0915 29.3511 52.597 28.7552 52.9845 28.0837Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M126.902 88.9332H235.489C235.489 88.9332 251.188 78.4628 247.912 50.9826C244.637 23.5024 226.984 41.8347 220.444 60.1427H172.035C172.035 60.1427 161.565 39.214 144.567 41.8347C127.57 44.4553 126.902 88.9332 126.902 88.9332Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M153.584 83.4827C153.738 76.2044 151.641 69.0562 147.579 63.0151V55.3716H134.488C123.932 55.3716 115.379 67.953 115.379 83.4827C115.379 99.0123 123.932 111.618 134.488 111.618H147.579V103.975C151.646 97.9266 153.743 90.7691 153.584 83.4827Z"
                          fill="#263238"></path>
                        <path
                          d="M147.577 111.594C158.124 111.594 166.674 99.008 166.674 83.4827C166.674 67.9573 158.124 55.3716 147.577 55.3716C137.03 55.3716 128.48 67.9573 128.48 83.4827C128.48 99.008 137.03 111.594 147.577 111.594Z"
                          fill="#263238"></path>
                        <mask id="mask1_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="128"
                          y="55" width="39" height="57">
                          <path
                            d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                            fill="white"></path>
                        </mask>
                        <g mask="url(#mask1_11394_83165)">
                          <path opacity="0.2"
                            d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                            fill="white"></path>
                        </g>
                        <path
                          d="M35.5843 55.3716H23.2212C13.2482 55.3716 5.16797 67.953 5.16797 83.4827C5.16797 99.0123 13.2482 111.618 23.2212 111.618H35.5843C45.5572 111.618 53.6375 99.0366 53.6375 83.5069C53.6375 67.9773 45.5572 55.3716 35.5843 55.3716Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                          fill="white"></path>
                        <path
                          d="M243.024 83.4827C243.173 76.4698 241.872 69.5018 239.203 63.0151V55.3716H227.64C220.919 55.3716 215.508 67.953 215.508 83.4827C215.508 99.0123 220.943 111.594 227.64 111.594H239.203V103.95C241.872 97.4635 243.173 90.4955 243.024 83.4827Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                          fill="#263238"></path>
                        <mask id="mask2_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="227"
                          y="55" width="25" height="57">
                          <path
                            d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                            fill="white"></path>
                        </mask>
                        <g mask="url(#mask2_11394_83165)">
                          <path opacity="0.2"
                            d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                            fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </g>
                        <path
                          d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                          stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path
                          d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                          fill="white"></path>
                        <path
                          d="M131.474 90.3511C131.474 67.9544 139.263 49.792 148.86 49.792C158.457 49.792 166.246 67.9908 166.246 90.3511C166.246 91.5643 166.246 92.7776 166.173 93.9908H228.11C228.11 92.7776 228.049 91.5643 228.049 90.3511C228.049 67.9423 233.727 49.7434 240.764 49.7434C245.435 49.7434 249.512 57.8601 251.683 69.9199V36.8829C251.686 35.3748 251.159 33.9137 250.194 32.7542C249.23 31.5947 247.889 30.8103 246.406 30.5376L246.102 30.4769C241.82 29.6155 234.091 21.4625 222.068 11.162C210.045 0.861426 199.319 0 199.319 0H90.8544C88.8048 0.000624519 86.8128 0.678833 85.1885 1.92907L48.2085 30.4769C48.2085 30.4769 15.0988 36.9072 7.81931 44.211C0.539779 51.5148 1.37693 93.9544 1.37693 93.9544H131.571C131.498 92.8018 131.474 91.5886 131.474 90.3511Z"
                          fill="white"></path>
                        <path
                          d="M8.73478 54.471C7.94599 53.7032 6.57408 53.1747 5.5575 52.8655C4.81654 52.6401 4.91106 49.9452 4.91106 49.9452C4.758 50.5829 4.19755 52.7992 3.76945 54.9395C3.38365 56.7652 3.19141 58.3285 3.19141 58.3285H10.7301C10.7301 58.3285 9.95016 55.654 8.73478 54.471Z"
                          fill="#E61464"></path>
                        <path
                          d="M93.135 55.7083C91.5413 57.3036 89.6905 60.3426 89.6905 60.3426C89.6905 60.3426 111.674 60.3426 124.605 58.4028C129.069 55.653 134.777 52.0855 137.307 45.5473C137.881 44.0623 138.297 41.592 138.297 41.592C127.737 45.3064 100.709 48.1267 93.135 55.7083Z"
                          fill="#CC1259"></path>
                        <path
                          d="M172.683 0H90.849C88.7993 0.000624519 86.8074 0.678833 85.1831 1.92907L48.2031 30.4769H152.931L172.683 0Z"
                          fill="white"></path>
                        <path
                          d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                          fill="#263238"></path>
                        <mask id="mask3_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="152"
                          y="3" width="72" height="28">
                          <path
                            d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                            fill="white"></path>
                        </mask>
                        <g mask="url(#mask3_11394_83165)">
                          <path opacity="0.2"
                            d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                            fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </g>
                        <path
                          d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                          stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M82.6842 3.86023L48.1914 30.479H152.932L170.172 3.86023H82.6842Z" fill="#263238"
                          stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M15.5742 52.8047V61.8192L22.0045 66.5387H73.9439L85.9672 61.3945V52.8047H15.5742Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M110.868 75.1103H3.98018L2.54855 63.6572C1.89339 69.9539 1.60221 76.6633 1.46875 82.1714L1.84486 83.7001L3.98018 79.4052H13.1524L7.42582 84.1247H92.3536L89.3084 79.4052H110.807L116.813 82.8387H121.108V61.0001L110.868 75.1103Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M194.81 30.4792H198.389C198.013 25.2136 196.509 13.6149 190.612 3.86035H186.973C189.096 8.34939 193.997 19.9117 194.81 30.4792Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M117.413 11.0902L118.723 14.7299C117.111 16.2711 116.17 18.3847 116.103 20.6142V30.5265H108.059L114.792 11.1144L117.413 11.0902ZM79.8022 15.9432C74.2819 19.3039 65.9347 26.7169 61.8945 30.4294H88.7802L102.696 9.07617L93.5362 9.7313C88.7043 11.2091 84.087 13.3138 79.8022 15.9917V15.9432ZM135.406 13.3468L132.13 12.6916H127.556L126.246 14.002C126.246 14.002 129.521 14.3295 129.885 16.295C130.111 18.2483 130.22 20.2131 130.213 22.1793H132.506C132.506 22.1793 132.506 18.5395 134.144 16.9502C135.07 16.0145 136.054 15.1392 137.092 14.3295L135.406 13.3468Z"
                          fill="#525252" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M175.309 28.0837H174.775C174.248 28.0833 173.728 27.9579 173.259 27.7178C172.789 27.4776 172.383 27.1295 172.074 26.7021C171.765 26.2747 171.562 25.7801 171.481 25.2589C171.4 24.7378 171.444 24.2049 171.609 23.7039C172.276 21.6535 173.404 19.5788 175.309 19.2634C179.228 18.6082 190.026 21.8719 190.026 24.1164C190.026 26.3609 190.026 27.3801 188.716 27.3801H180.538L174.035 34.6353L173.89 34.514C173.553 34.2304 173.282 33.8766 173.096 33.4774C172.91 33.0781 172.814 32.6431 172.814 32.2028C172.814 31.7624 172.91 31.3274 173.096 30.9281C173.282 30.5289 173.553 30.1751 173.89 29.8915C174.461 29.3726 174.941 28.7615 175.309 28.0837Z"
                          fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M173.862 34.5169L174.008 34.6382L180.547 27.4315H188.724C189.804 27.4315 189.938 26.7521 190.023 25.2234H171.496C171.605 26.0178 171.998 26.7457 172.603 27.2723C173.207 27.7988 173.982 28.0882 174.784 28.0867H175.318C174.938 28.7672 174.446 29.3785 173.862 29.8944C173.525 30.178 173.254 30.5318 173.068 30.931C172.883 31.3303 172.786 31.7653 172.786 32.2057C172.786 32.646 172.883 33.081 173.068 33.4803C173.254 33.8795 173.525 34.2333 173.862 34.5169Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path d="M194.056 38.373H186.691V39.9503H194.056V38.373Z" fill="#263238" stroke="#263238"
                          stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M227.177 38.373H219.812V39.9503H227.177V38.373Z" fill="#263238" stroke="#263238"
                          stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                          d="M221.348 87.8041H166.206C166.206 88.6129 166.206 89.4622 166.206 90.3519C166.206 91.5652 166.206 92.7784 166.133 93.9917H228.069C228.069 92.7784 228.009 91.5652 228.009 90.3519C228.009 90.267 228.009 90.1821 228.009 90.0971L221.348 87.8041Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M131.472 90.3517C131.472 89.5024 131.472 88.6531 131.472 87.8038H1.38672C1.38672 91.5892 1.38672 94.0036 1.38672 94.0036H131.569C131.496 92.8025 131.472 91.5892 131.472 90.3517Z"
                          fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M249.355 35.1875C248.701 35.1875 248.057 35.3578 247.488 35.6817C246.919 36.0056 246.445 36.472 246.11 37.035C245.776 37.598 245.594 38.2383 245.583 38.8928C245.571 39.5474 245.73 40.1938 246.043 40.7685L251.661 51.069V36.886C251.654 36.3237 251.572 35.7647 251.418 35.2239L249.355 35.1875Z"
                          fill="#CC1259"></path>
                        <path d="M198.859 35.9357C198.859 35.9357 210.628 52.9212 198.859 81.7239" stroke="#263238"
                          stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                          d="M122.01 70.6865H96.1471H43.1284H2.39453V87.6341V93.963H131.708C131.961 88.408 131.665 85.2564 132.355 79.7385C132.994 74.6282 134.941 66.8071 134.941 66.8071L122.01 70.6865Z"
                          fill="#E7E7E7" fill-opacity="0.93"></path>
                      </mask>
                      <g mask="url(#mask0_11394_83165)">
                        <path d="M-60.4609 -47.4076H318.199V144.023H-60.4609V-47.4076Z" fill="#F1F1F1"></path>
                      </g>
                      <path
                        d="M8.73869 54.471C7.9499 53.7032 6.57798 53.1747 5.56141 52.8655C4.82045 52.6401 4.91496 49.9452 4.91496 49.9452C4.76191 50.5829 4.20146 52.7992 3.77336 54.9395C3.38756 56.7652 3.19531 58.3285 3.19531 58.3285H10.734C10.734 58.3285 9.95406 55.654 8.73869 54.471Z"
                        fill="#DADADA"></path>
                      <path
                        d="M93.1389 55.7083C91.5452 57.3036 89.6944 60.3426 89.6944 60.3426C89.6944 60.3426 111.678 60.3426 124.609 58.4028C129.073 55.653 134.781 52.0855 137.311 45.5473C137.885 44.0623 138.301 41.592 138.301 41.592C127.74 45.3064 100.713 48.1267 93.1389 55.7083Z"
                        fill="#DADADA"></path>
                      <path d="M15.5742 52.8046V61.819L22.0045 66.5386H73.9439L85.9672 61.3944V52.8046H15.5742Z"
                        fill="#D8D8D8"></path>
                      <path
                        d="M249.359 35.1874C248.705 35.1873 248.061 35.3576 247.492 35.6816C246.923 36.0055 246.448 36.4719 246.114 37.0349C245.78 37.5979 245.598 38.2381 245.587 38.8927C245.575 39.5473 245.734 40.1937 246.047 40.7684L251.665 51.0689V36.8859C251.658 36.3235 251.576 35.7646 251.422 35.2238L249.359 35.1874Z"
                        fill="#DADADA"></path>
                      <path d="M82.6881 3.86011L48.1953 30.4789H153.475L170.877 3.86011H82.6881Z" fill="#D9D9D9"
                        stroke="#D9D9D9" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                        fill="#DEDCDC"></path>
                      <path
                        d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                        fill="#DEDCDC"></path>
                      <path d="M195.857 41.9735H188.492V43.5507H195.857V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                        stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path d="M228.982 41.9735H221.617V43.5507H228.982V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                        stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M194.814 30.4792H198.393C198.017 25.2136 196.513 13.6149 190.616 3.86035H186.977C189.1 8.34939 194.001 19.9117 194.814 30.4792Z"
                        fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M112.673 78.7109H5.78486L4.35323 67.2577C3.69808 73.5545 3.4069 80.2638 3.27344 85.772L3.64954 87.3007L5.78486 83.0058H14.9571L9.2305 87.7253H94.1583L91.1131 83.0058H112.612L118.618 86.4393H122.912V64.6007L112.673 78.7109Z"
                        fill="#D8D8D8" stroke="#D8D8D8" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="an-vehicle-ymm">
                    <span class="an-vehicle-year">
                      {{vehicle.Year}}
                    </span>
                    <span class="an-vehicle-mm">
                      {{vehicle.Make}} {{vehicle.Model}}
                    </span>
                  </div>
                </div>
                <div class="an-vehicle-body spreadout-veh-body">
                  <div class="an-veh-req">
                    *Indicates required field
                  </div>
                  <div class="an-vehicle-trim" [id]="'an-model-' + vehicle.VehicleId" *ngIf="vehicle.Trim.length === 0">
                    <an-select [enableCustomcss]="true" [items]="getVehicleModel(vehicle)" [id]="vehicle.VehicleId"
                      label="Model *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anaddVehModel">
                    </an-select>
                  </div>
                  <div class="an-vehicle-trim" [id]="'an-trim-' + vehicle.VehicleId">
                    <an-select [enableCustomcss]="true" [items]="trimValue[vehicle.VehicleId]" [id]="vehicle.VehicleId"
                      label="Trim *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anSelect" [idValue]="getId(vehicle)">
                    </an-select>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="an-vehicles-body" *ngIf="isFinishAddingFlow">
          <div class="an-vehicles ">
            <div class="an-finish-veh-section">
              <div class="an-finish-veh-left-section">
                <div class="an-finish-veh-img" *ngIf="isImageURLValid(data.finishVehicle)">
                  <img class="an-finish-veh-image" [src]="data.finishVehicle?.ImageUrl" />
                </div>
                <div class="an-vehicle-image" *ngIf="!isImageURLValid(data.finishVehicle)"
                  [ngClass]="{'an-vehicle-image-disable':!isImageURLValid(data.finishVehicle), 'mb-10': !isImageURLValid(data.finishVehicle)}">
                  <svg width="100%" height="100%" viewBox="0 0 252 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_11394_83165" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                      width="252" height="113">
                      <path
                        d="M52.9845 28.0837H52.4628C51.9354 28.0833 51.4156 27.9579 50.9461 27.7178C50.4765 27.4776 50.0706 27.1295 49.7615 26.7021C49.4525 26.2747 49.2493 25.7801 49.1684 25.2589C49.0875 24.7378 49.1313 24.2049 49.2962 23.7039C49.9635 21.6535 51.0919 19.5788 52.936 19.2634C56.867 18.6082 67.6649 21.8719 67.6649 24.1164C67.6649 26.3609 67.6649 27.3801 66.3546 27.3801H58.1773L51.6378 34.5868L51.4923 34.4655C51.1553 34.1819 50.8845 33.8281 50.6987 33.4289C50.5129 33.0296 50.4166 32.5946 50.4166 32.1542C50.4166 31.7139 50.5129 31.2788 50.6987 30.8796C50.8845 30.4803 51.1553 30.1265 51.4923 29.843C52.0915 29.3511 52.597 28.7552 52.9845 28.0837Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M126.902 88.9332H235.489C235.489 88.9332 251.188 78.4628 247.912 50.9826C244.637 23.5024 226.984 41.8347 220.444 60.1427H172.035C172.035 60.1427 161.565 39.214 144.567 41.8347C127.57 44.4553 126.902 88.9332 126.902 88.9332Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M153.584 83.4827C153.738 76.2044 151.641 69.0562 147.579 63.0151V55.3716H134.488C123.932 55.3716 115.379 67.953 115.379 83.4827C115.379 99.0123 123.932 111.618 134.488 111.618H147.579V103.975C151.646 97.9266 153.743 90.7691 153.584 83.4827Z"
                        fill="#263238"></path>
                      <path
                        d="M147.577 111.594C158.124 111.594 166.674 99.008 166.674 83.4827C166.674 67.9573 158.124 55.3716 147.577 55.3716C137.03 55.3716 128.48 67.9573 128.48 83.4827C128.48 99.008 137.03 111.594 147.577 111.594Z"
                        fill="#263238"></path>
                      <mask id="mask1_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="128" y="55"
                        width="39" height="57">
                        <path
                          d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask1_11394_83165)">
                        <path opacity="0.2"
                          d="M147.585 111.595C158.132 111.595 166.682 99.0095 166.682 83.4841C166.682 67.9588 158.132 55.373 147.585 55.373C137.038 55.373 128.488 67.9588 128.488 83.4841C128.488 99.0095 137.038 111.595 147.585 111.595Z"
                          fill="white"></path>
                      </g>
                      <path
                        d="M35.5843 55.3716H23.2212C13.2482 55.3716 5.16797 67.953 5.16797 83.4827C5.16797 99.0123 13.2482 111.618 23.2212 111.618H35.5843C45.5572 111.618 53.6375 99.0366 53.6375 83.5069C53.6375 67.9773 45.5572 55.3716 35.5843 55.3716Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                        fill="white"></path>
                      <path
                        d="M243.024 83.4827C243.173 76.4698 241.872 69.5018 239.203 63.0151V55.3716H227.64C220.919 55.3716 215.508 67.953 215.508 83.4827C215.508 99.0123 220.943 111.594 227.64 111.594H239.203V103.95C241.872 97.4635 243.173 90.4955 243.024 83.4827Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                        fill="#263238"></path>
                      <mask id="mask2_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="227" y="55"
                        width="25" height="57">
                        <path
                          d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask2_11394_83165)">
                        <path opacity="0.2"
                          d="M239.215 111.595C245.929 111.595 251.372 99.0095 251.372 83.4841C251.372 67.9588 245.929 55.373 239.215 55.373C232.501 55.373 227.059 67.9588 227.059 83.4841C227.059 99.0095 232.501 111.595 239.215 111.595Z"
                          fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </g>
                      <path
                        d="M239.208 111.594C245.922 111.594 251.364 99.008 251.364 83.4827C251.364 67.9573 245.922 55.3716 239.208 55.3716C232.494 55.3716 227.051 67.9573 227.051 83.4827C227.051 99.008 232.494 111.594 239.208 111.594Z"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                        fill="white"></path>
                      <path
                        d="M131.474 90.3511C131.474 67.9544 139.263 49.792 148.86 49.792C158.457 49.792 166.246 67.9908 166.246 90.3511C166.246 91.5643 166.246 92.7776 166.173 93.9908H228.11C228.11 92.7776 228.049 91.5643 228.049 90.3511C228.049 67.9423 233.727 49.7434 240.764 49.7434C245.435 49.7434 249.512 57.8601 251.683 69.9199V36.8829C251.686 35.3748 251.159 33.9137 250.194 32.7542C249.23 31.5947 247.889 30.8103 246.406 30.5376L246.102 30.4769C241.82 29.6155 234.091 21.4625 222.068 11.162C210.045 0.861426 199.319 0 199.319 0H90.8544C88.8048 0.000624519 86.8128 0.678833 85.1885 1.92907L48.2085 30.4769C48.2085 30.4769 15.0988 36.9072 7.81931 44.211C0.539779 51.5148 1.37693 93.9544 1.37693 93.9544H131.571C131.498 92.8018 131.474 91.5886 131.474 90.3511Z"
                        fill="white"></path>
                      <path
                        d="M8.73478 54.471C7.94599 53.7032 6.57408 53.1747 5.5575 52.8655C4.81654 52.6401 4.91106 49.9452 4.91106 49.9452C4.758 50.5829 4.19755 52.7992 3.76945 54.9395C3.38365 56.7652 3.19141 58.3285 3.19141 58.3285H10.7301C10.7301 58.3285 9.95016 55.654 8.73478 54.471Z"
                        fill="#E61464"></path>
                      <path
                        d="M93.135 55.7083C91.5413 57.3036 89.6905 60.3426 89.6905 60.3426C89.6905 60.3426 111.674 60.3426 124.605 58.4028C129.069 55.653 134.777 52.0855 137.307 45.5473C137.881 44.0623 138.297 41.592 138.297 41.592C127.737 45.3064 100.709 48.1267 93.135 55.7083Z"
                        fill="#CC1259"></path>
                      <path
                        d="M172.683 0H90.849C88.7993 0.000624519 86.8074 0.678833 85.1831 1.92907L48.2031 30.4769H152.931L172.683 0Z"
                        fill="white"></path>
                      <path
                        d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                        fill="#263238"></path>
                      <mask id="mask3_11394_83165" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="152" y="3"
                        width="72" height="28">
                        <path
                          d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                          fill="white"></path>
                      </mask>
                      <g mask="url(#mask3_11394_83165)">
                        <path opacity="0.2"
                          d="M152.926 30.4762H216.488C216.488 30.4762 225.503 27.4674 222.931 16.3054C222.057 12.5201 204.04 3.85742 201.468 3.85742H170.53L152.926 30.4762Z"
                          fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      </g>
                      <path
                        d="M152.934 30.479H216.496C216.496 30.479 225.51 27.4702 222.938 16.3082C222.065 12.5229 204.048 3.86023 201.476 3.86023H170.538L152.934 30.479Z"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M82.6842 3.86023L48.1914 30.479H152.932L170.172 3.86023H82.6842Z" fill="#263238"
                        stroke="#263238" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M15.5742 52.8047V61.8192L22.0045 66.5387H73.9439L85.9672 61.3945V52.8047H15.5742Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M110.868 75.1103H3.98018L2.54855 63.6572C1.89339 69.9539 1.60221 76.6633 1.46875 82.1714L1.84486 83.7001L3.98018 79.4052H13.1524L7.42582 84.1247H92.3536L89.3084 79.4052H110.807L116.813 82.8387H121.108V61.0001L110.868 75.1103Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M194.81 30.4792H198.389C198.013 25.2136 196.509 13.6149 190.612 3.86035H186.973C189.096 8.34939 193.997 19.9117 194.81 30.4792Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M117.413 11.0902L118.723 14.7299C117.111 16.2711 116.17 18.3847 116.103 20.6142V30.5265H108.059L114.792 11.1144L117.413 11.0902ZM79.8022 15.9432C74.2819 19.3039 65.9347 26.7169 61.8945 30.4294H88.7802L102.696 9.07617L93.5362 9.7313C88.7043 11.2091 84.087 13.3138 79.8022 15.9917V15.9432ZM135.406 13.3468L132.13 12.6916H127.556L126.246 14.002C126.246 14.002 129.521 14.3295 129.885 16.295C130.111 18.2483 130.22 20.2131 130.213 22.1793H132.506C132.506 22.1793 132.506 18.5395 134.144 16.9502C135.07 16.0145 136.054 15.1392 137.092 14.3295L135.406 13.3468Z"
                        fill="#525252" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M175.309 28.0837H174.775C174.248 28.0833 173.728 27.9579 173.259 27.7178C172.789 27.4776 172.383 27.1295 172.074 26.7021C171.765 26.2747 171.562 25.7801 171.481 25.2589C171.4 24.7378 171.444 24.2049 171.609 23.7039C172.276 21.6535 173.404 19.5788 175.309 19.2634C179.228 18.6082 190.026 21.8719 190.026 24.1164C190.026 26.3609 190.026 27.3801 188.716 27.3801H180.538L174.035 34.6353L173.89 34.514C173.553 34.2304 173.282 33.8766 173.096 33.4774C172.91 33.0781 172.814 32.6431 172.814 32.2028C172.814 31.7624 172.91 31.3274 173.096 30.9281C173.282 30.5289 173.553 30.1751 173.89 29.8915C174.461 29.3726 174.941 28.7615 175.309 28.0837Z"
                        fill="white" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M173.862 34.5169L174.008 34.6382L180.547 27.4315H188.724C189.804 27.4315 189.938 26.7521 190.023 25.2234H171.496C171.605 26.0178 171.998 26.7457 172.603 27.2723C173.207 27.7988 173.982 28.0882 174.784 28.0867H175.318C174.938 28.7672 174.446 29.3785 173.862 29.8944C173.525 30.178 173.254 30.5318 173.068 30.931C172.883 31.3303 172.786 31.7653 172.786 32.2057C172.786 32.646 172.883 33.081 173.068 33.4803C173.254 33.8795 173.525 34.2333 173.862 34.5169Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path d="M194.056 38.373H186.691V39.9503H194.056V38.373Z" fill="#263238" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M227.177 38.373H219.812V39.9503H227.177V38.373Z" fill="#263238" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M221.348 87.8041H166.206C166.206 88.6129 166.206 89.4622 166.206 90.3519C166.206 91.5652 166.206 92.7784 166.133 93.9917H228.069C228.069 92.7784 228.009 91.5652 228.009 90.3519C228.009 90.267 228.009 90.1821 228.009 90.0971L221.348 87.8041Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M131.472 90.3517C131.472 89.5024 131.472 88.6531 131.472 87.8038H1.38672C1.38672 91.5892 1.38672 94.0036 1.38672 94.0036H131.569C131.496 92.8025 131.472 91.5892 131.472 90.3517Z"
                        fill="#263238" stroke="#263238" stroke-width="0.850212" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                      <path
                        d="M249.355 35.1875C248.701 35.1875 248.057 35.3578 247.488 35.6817C246.919 36.0056 246.445 36.472 246.11 37.035C245.776 37.598 245.594 38.2383 245.583 38.8928C245.571 39.5474 245.73 40.1938 246.043 40.7685L251.661 51.069V36.886C251.654 36.3237 251.572 35.7647 251.418 35.2239L249.355 35.1875Z"
                        fill="#CC1259"></path>
                      <path d="M198.859 35.9357C198.859 35.9357 210.628 52.9212 198.859 81.7239" stroke="#263238"
                        stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M122.01 70.6865H96.1471H43.1284H2.39453V87.6341V93.963H131.708C131.961 88.408 131.665 85.2564 132.355 79.7385C132.994 74.6282 134.941 66.8071 134.941 66.8071L122.01 70.6865Z"
                        fill="#E7E7E7" fill-opacity="0.93"></path>
                    </mask>
                    <g mask="url(#mask0_11394_83165)">
                      <path d="M-60.4609 -47.4076H318.199V144.023H-60.4609V-47.4076Z" fill="#F1F1F1"></path>
                    </g>
                    <path
                      d="M8.73869 54.471C7.9499 53.7032 6.57798 53.1747 5.56141 52.8655C4.82045 52.6401 4.91496 49.9452 4.91496 49.9452C4.76191 50.5829 4.20146 52.7992 3.77336 54.9395C3.38756 56.7652 3.19531 58.3285 3.19531 58.3285H10.734C10.734 58.3285 9.95406 55.654 8.73869 54.471Z"
                      fill="#DADADA"></path>
                    <path
                      d="M93.1389 55.7083C91.5452 57.3036 89.6944 60.3426 89.6944 60.3426C89.6944 60.3426 111.678 60.3426 124.609 58.4028C129.073 55.653 134.781 52.0855 137.311 45.5473C137.885 44.0623 138.301 41.592 138.301 41.592C127.74 45.3064 100.713 48.1267 93.1389 55.7083Z"
                      fill="#DADADA"></path>
                    <path d="M15.5742 52.8046V61.819L22.0045 66.5386H73.9439L85.9672 61.3944V52.8046H15.5742Z"
                      fill="#D8D8D8"></path>
                    <path
                      d="M249.359 35.1874C248.705 35.1873 248.061 35.3576 247.492 35.6816C246.923 36.0055 246.448 36.4719 246.114 37.0349C245.78 37.5979 245.598 38.2381 245.587 38.8927C245.575 39.5473 245.734 40.1937 246.047 40.7684L251.665 51.0689V36.8859C251.658 36.3235 251.576 35.7646 251.422 35.2238L249.359 35.1874Z"
                      fill="#DADADA"></path>
                    <path d="M82.6881 3.86011L48.1953 30.4789H153.475L170.877 3.86011H82.6881Z" fill="#D9D9D9"
                      stroke="#D9D9D9" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M147.571 105.915C155.986 105.915 162.809 95.8715 162.809 83.482C162.809 71.0926 155.986 61.049 147.571 61.049C139.155 61.049 132.332 71.0926 132.332 83.482C132.332 95.8715 139.155 105.915 147.571 105.915Z"
                      fill="#DEDCDC"></path>
                    <path
                      d="M248.908 83.482C248.908 95.8693 244.565 105.915 239.202 105.915C233.84 105.915 229.496 95.8693 229.496 83.482C229.496 71.0947 233.84 61.049 239.202 61.049C244.565 61.049 248.908 71.0947 248.908 83.482Z"
                      fill="#DEDCDC"></path>
                    <path d="M195.857 41.9735H188.492V43.5507H195.857V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                      stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path d="M228.982 41.9735H221.617V43.5507H228.982V41.9735Z" fill="#CBCBCB" stroke="#CBCBCB"
                      stroke-opacity="0.737255" stroke-width="0.850212" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path
                      d="M194.814 30.4792H198.393C198.017 25.2136 196.513 13.6149 190.616 3.86035H186.977C189.1 8.34939 194.001 19.9117 194.814 30.4792Z"
                      fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.850212" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path
                      d="M112.673 78.7109H5.78486L4.35323 67.2577C3.69808 73.5545 3.4069 80.2638 3.27344 85.772L3.64954 87.3007L5.78486 83.0058H14.9571L9.2305 87.7253H94.1583L91.1131 83.0058H112.612L118.618 86.4393H122.912V64.6007L112.673 78.7109Z"
                      fill="#D8D8D8" stroke="#D8D8D8" stroke-width="0.850212" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="an-finsih-vehicle-ymm">
                  <span class="an-vehicle-year">
                    {{data.finishVehicle?.Year}}
                  </span>
                  <span class="an-vehicle-mm">
                    {{data.finishVehicle?.Make}} {{anSelectModelControl?.value?.description || finsihVehicleModel}}
                  </span>
                </div>

              </div>
              <div class="seperator">

              </div>
              <div class="an-finish-veh-details" *ngIf="!isFinishVehicleModelNeeded">
                <div class="an-veh-req">
                  {{data.finishAddingLabelData.requiredField || '*Indicates required field'}}
                </div>
                <div class="an-veh-name-trim ">
                  <span class="an-veh-nickName an-finish-veh-option ">
                    <an-input label={{data.finishAddingLabelData.VehicleNickname}}
                      placeHolder={{data.finishAddingLabelData?.AddNickName}} name="anInput"
                      formControlName="anInputNickName" [required]="false" [enableCustomcss]="true">
                    </an-input>
                  </span>
                  <span class="an-veh-trim an-finish-veh-option ">
                    <an-select [enableCustomcss]="true" [items]="getAddnlDetailsForFinsihVehicle('Trim')" id="Trim"
                      label="Trim *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anSelectTrim">
                    </an-select>
                  </span>
                </div>
                <div class="an-veh-color">
                  <span class="an-veh-ext-color an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="exteriorColor" id="ExteriorColor"
                      label={{data.finishAddingLabelData.ExteriorColor}} placeholder="Select"
                      formControlName="anSelectExteriorColor" [isDefaultNotNeeded]="isDefaultNotNeeded">
                    </an-select>
                  </span>
                  <span class="an-veh-seat-color an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="seatColor" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      [label]="data.finishAddingLabelData.SeatColor" placeholder="Select"
                      formControlName="anSelectSeatColor">
                    </an-select>
                  </span>
                </div>
                <div class="an-veh-material-mileage">
                  <span class="an-veh-material an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="getSeatMaterial()"
                      [isDefaultNotNeeded]="isDefaultNotNeeded" id="SeatMaterial"
                      label={{data.finishAddingLabelData.SeatMaterial}} placeholder="Select"
                      formControlName="anSelectSeatMaterial">
                    </an-select>
                  </span>
                  <span class="an-veh-mileage an-finish-veh-option  ">
                    <an-input label={{data.finishAddingLabelData.EstimatedMileage}}
                      placeHolder={{data.finishAddingLabelData?.AddMileage}} name="anInput"
                      formControlName="anInputMileage" [required]="false" [numbersOnly]="true" [maxlength]="7"
                      [enableCustomcss]="true">
                    </an-input>
                  </span>
                </div>
              </div>
              <div class="an-finish-veh-details" *ngIf="isFinishVehicleModelNeeded">
                <div class="an-veh-req">
                  {{data.finishAddingLabelData.requiredField || '*Indicates required field'}}
                </div>
                <div class="an-veh-name-trim ">
                  <span class="an-veh-nickName an-finish-veh-option ">
                    <an-input label={{data.finishAddingLabelData.VehicleNickname}}
                      placeHolder={{data.finishAddingLabelData?.AddNickName}} name="anInput"
                      formControlName="anInputNickName" [required]="false" [enableCustomcss]="true">
                    </an-input>
                  </span>

                  <span class="an-veh-trim an-finish-veh-option ">
                    <an-select [enableCustomcss]="true" [items]="getAddnlDetailsForFinsihVehicle('models')" id="Model"
                      label="Model *" placeholder="Select" [isDefaultNotNeeded]="isDefaultNotNeeded"
                      formControlName="anSelectModel">
                    </an-select>
                  </span>
                </div>
                <div class="an-veh-color">
                  <span class="an-veh-trim an-finish-veh-option ">
                    <an-select [enableCustomcss]="true" [items]="trimDD" id="Trim" label="Trim *" placeholder="Select"
                      [isDefaultNotNeeded]="isDefaultNotNeeded" formControlName="anSelectTrim">
                    </an-select>
                  </span>
                  <span class="an-veh-ext-color an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="exteriorColor" id="ExteriorColor"
                      label={{data.finishAddingLabelData.ExteriorColor}} placeholder="Select"
                      formControlName="anSelectExteriorColor" [isDefaultNotNeeded]="isDefaultNotNeeded">
                    </an-select>
                  </span>
                </div>
                <div class="an-veh-material-mileage">
                  <span class="an-veh-seat-color an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="seatColor"
                      [label]="data.finishAddingLabelData.SeatColor" placeholder="Select"
                      [isDefaultNotNeeded]="isDefaultNotNeeded" formControlName="anSelectSeatColor">
                    </an-select>
                  </span>
                  <span class="an-veh-material an-finish-veh-option">
                    <an-select [enableCustomcss]="true" [items]="getSeatMaterial()"
                      [isDefaultNotNeeded]="isDefaultNotNeeded" id="SeatMaterial"
                      label={{data.finishAddingLabelData.SeatMaterial}} placeholder="Select"
                      formControlName="anSelectSeatMaterial">
                    </an-select>
                  </span>
                </div>
                <div class="an-veh-material-mileage">
                  <span class="an-veh-mileage an-finish-veh-option  ">
                    <an-input label={{data.finishAddingLabelData.EstimatedMileage}}
                      placeHolder={{data.finishAddingLabelData?.AddMileage}} name="anInput"
                      formControlName="anInputMileage" [required]="false" [numbersOnly]="true" [maxlength]="7"
                      [enableCustomcss]="true">
                    </an-input>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vehicle-footer">
        <ng-container *ngIf="vehicles.length > 1 || isFinishAddingFlow; else addRemove">
          <button class="an-button an-ask" (click)="closeModal('askmelater')">
            {{data.ask}}
          </button>
          <button class="an-button an-done" [ngClass]="{'enableDone': isDoneActive, 'enableFinishDone': isenableFinishDone}"
            (click)="closeModal('done')">
            {{data.done}}
          </button>
        </ng-container>
      </div>

      <!-- Don't remove the commentted out code-->
      <!-- <div class="vehicle-footer"  *ngIf="vehicles.length  > 1 || isFinishAddingFlow || ismobile">
        <button class="an-button an-ask" (click)="closeModal('askmelater')">
          {{data.ask}}
        </button>
        <button class="an-button an-done" [ngClass]="{'enableDone': isDoneActive, 'enableFinishDone': isenableFinishDone}" (click)="closeModal('done')">
          {{data.done}}
        </button>
      </div>

      <div class="vehicle-footer vehicle"  *ngIf="vehicles.length  ===  1 && !isFinishAddingFlow && !ismobile">
        <div class="an-vehicle-button-group an-add-remove" [id]="'an-trim-button-' + vehicles[0].VehicleId">
          <div (click)="removeVehicle(0,vehicles[0],true)" class="an-button an-ask an-add-remove-button" [id]="'an-vehicle-remove-' + vehicles[0].VehicleId" [ngClass]="{'disableRemove': isRemoveDiasbleSpreadOut}">

            {{data.remove}}
          </div>
          <div class="">
            <button class="dnone w-220" [id]="'an-vehicle-added-'+0">
              <span style="margin-right: 8px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M8.33203 0.87085C4.19637 0.87085 0.832031 4.23518 0.832031 8.37085C0.832031 12.5065 4.19637 15.8708 8.33203 15.8708C12.4677 15.8708 15.832 12.5065 15.832 8.37085C15.832 4.23518 12.4677 0.87085 8.33203 0.87085Z"
                    fill="#BCBCBC" />
                  <path
                    d="M12.1335 6.7814L8.07092 10.8438C7.94904 10.9657 7.78905 11.027 7.62906 11.027C7.46907 11.027 7.30908 10.9657 7.1872 10.8438L5.15599 8.81261C4.91154 8.56828 4.91154 8.17323 5.15599 7.9289C5.40032 7.68446 5.79526 7.68446 6.0397 7.9289L7.62906 9.51826L11.2497 5.89769C11.4941 5.65324 11.889 5.65324 12.1335 5.89769C12.3778 6.14202 12.3778 6.53696 12.1335 6.7814Z"
                    fill="#FAFAFA" />
                </svg>
              </span>
              Vehicle Added
            </button>
          </div>
          <div [id]="'an-vehicle-div-'+ 0" >
            <button  class="an-vehicle-button w-220" [id]="'an-vehicle-' + vehicles[0].VehicleId"
              (click)="addVehicle(0,vehicles[0], true)">
              <span [id]="'an-vehicle-image-'+0" class="dnone">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M6.99382e-07 8C8.37707e-07 6.41775 0.469193 4.87103 1.34824 3.55544C2.2273 2.23984 3.47672 1.21446 4.93853 0.608963C6.40034 0.00346195 8.00888 -0.154965 9.56072 0.153717C11.1126 0.462399 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16L8 14.8C9.34491 14.8 10.6596 14.4012 11.7779 13.654C12.8961 12.9068 13.7677 11.8448 14.2824 10.6022C14.7971 9.35971 14.9317 7.99246 14.6693 6.67339C14.407 5.35431 13.7593 4.14267 12.8083 3.19167C11.8573 2.24068 10.6457 1.59304 9.32662 1.33066C8.00754 1.06828 6.64029 1.20294 5.39775 1.71762C4.15522 2.23229 3.0932 3.10387 2.34601 4.22212C1.59881 5.34038 1.2 6.65509 1.2 8L6.99382e-07 8Z"
                    fill="white" />
                </svg>
              </span>
              {{data.addThisVehicle}}
            </button>

          </div>


        </div>
      </div> -->
    </ng-container>
  </div>
</div>

<ng-template #addRemove>
  <div class="an-vehicle-button-group an-add-remove" [id]="'an-trim-button-' + vehicles[0].VehicleId" *ngIf="vehicles.length == 1">
    <div (click)="removeVehicle(0, vehicles[0], true)" class="an-remove-button-template an-button an-ask an-add-remove-button"
      [id]="'an-vehicle-remove-' + vehicles[0].VehicleId" [ngClass]="{'disableRemove': isRemoveDiasbleSpreadOut}">
      {{data.remove}}
    </div>
    <div class="">
      <button class="dnone w-220" [id]="'an-vehicle-added-'+0">
        <span style="margin-right: 8px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M8.33203 0.87085C4.19637 0.87085 0.832031 4.23518 0.832031 8.37085C0.832031 12.5065 4.19637 15.8708 8.33203 15.8708C12.4677 15.8708 15.832 12.5065 15.832 8.37085C15.832 4.23518 12.4677 0.87085 8.33203 0.87085Z"
              fill="#BCBCBC" />
            <path
              d="M12.1335 6.7814L8.07092 10.8438C7.94904 10.9657 7.78905 11.027 7.62906 11.027C7.46907 11.027 7.30908 10.9657 7.1872 10.8438L5.15599 8.81261C4.91154 8.56828 4.91154 8.17323 5.15599 7.9289C5.40032 7.68446 5.79526 7.68446 6.0397 7.9289L7.62906 9.51826L11.2497 5.89769C11.4941 5.65324 11.889 5.65324 12.1335 5.89769C12.3778 6.14202 12.3778 6.53696 12.1335 6.7814Z"
              fill="#FAFAFA" />
          </svg>
        </span>
        Vehicle Added
      </button>
    </div>
    <div [id]="'an-vehicle-div-'+ 0">
      <button class="an-vehicle-button w-220" [id]="'an-vehicle-' + vehicles[0].VehicleId"
        (click)="addVehicle(0,vehicles[0], true)">
        <span [id]="'an-vehicle-image-'+0" class="dnone">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.99382e-07 8C8.37707e-07 6.41775 0.469193 4.87103 1.34824 3.55544C2.2273 2.23984 3.47672 1.21446 4.93853 0.608963C6.40034 0.00346195 8.00888 -0.154965 9.56072 0.153717C11.1126 0.462399 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16L8 14.8C9.34491 14.8 10.6596 14.4012 11.7779 13.654C12.8961 12.9068 13.7677 11.8448 14.2824 10.6022C14.7971 9.35971 14.9317 7.99246 14.6693 6.67339C14.407 5.35431 13.7593 4.14267 12.8083 3.19167C11.8573 2.24068 10.6457 1.59304 9.32662 1.33066C8.00754 1.06828 6.64029 1.20294 5.39775 1.71762C4.15522 2.23229 3.0932 3.10387 2.34601 4.22212C1.59881 5.34038 1.2 6.65509 1.2 8L6.99382e-07 8Z"
              fill="white" />
          </svg>
        </span>
        {{data.addThisVehicle}}
      </button>
    </div>
  </div>
</ng-template>
