<div #modal aria-hidden="true" class="new-modal " *ngIf="showNotificationModal" (click)="closeNotificationModal()" role="dialog"
  >
  <div class="new-modal-dialog">
    <div  id="auth-modal-centents" class="new-modal-content scrollBar" (click)="$event.stopPropagation()">

      <div class="notification-dropdown"  id="notification-dropdown">
        <div>
          <div class="notification-header">
            <an-close-icon class="mobileViewClose notifcation-close-icon" (onClick)="closeNotificationModal()"></an-close-icon>
            <div class="notification-title">
              Notifications
            </div>
            <button class="notification-read" *ngIf="enableMarkAllAsRead" [ngClass]="{'disableMarkAllAsRead': markallasRead }" (click)="markallAsRead('')">
              Mark All as Read
            </button>
          </div>
        </div>
        <ul class="notifcation-body">
          <ng-container *ngIf="sortedData.length > 0">
            <ng-container *ngFor="let notifyData of sortedData; let i = index">
              <li class="notifListItem" id="HappyOffer" *ngIf="notifyData.showWeOffer" (click)="openTradeIn(notifyData)">
                <a href="javascript:void(0)" class="notifListDetails">
                  <div class="notif-read" *ngIf="notifyData.alertStatus === 'READ'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#E7F2FA" />
                    </svg>
                  </div>
                  <div class="notif-read"  *ngIf="notifyData.alertStatus === 'UNREAD'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#3366CC" />
                    </svg>
                  </div>
                  <div class="notifIcon">
                    <span class="dt-icon dt-icon-mynotification-smc" *ngIf="notifyData.tradeInStatus"></span>
                    <span class="dt-icon dt-icon-mynotification-pending-trade" *ngIf="notifyData.tradeInPending"></span>
                    <span class="dt-icon dt-icon-mynotification-new-trade"
                      *ngIf="notifyData.tradeInExpire || notifyData.tradeInReject"></span>
                  </div>

                  <div class="notifContent">
                    <div class="listContent">
                      <div class="notifHeader">
                        <div class="notifheading" [ngClass]="notifyData.tradeInExpire?
                      'tradeInExpire': notifyData.tradeInReject? 'needmoreHead': ''">
                          {{notifyData.weOffer}}
                        </div>
                        <div class="notifDate">
                          {{notifyData.tradeinTimeNotif}}
                        </div>
                      </div>
                      <div class="list-text">{{notifyData.tradeInText}}</div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notifListItem dd" id="upcomingAppointment" *ngIf="notifyData.isAppt" (click)="openStoreAppt(notifyData)">
                <a href="javascript:void(0)" class="notifListDetails">
                  <div class="notif-read"  *ngIf="notifyData.alertStatus === 'UNREAD'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#3366CC" />
                    </svg>
                  </div>
                  <div class="notif-read" *ngIf="notifyData.alertStatus === 'READ'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#E7F2FA" />
                    </svg>
                  </div>
                  <div class="notifIcon">
                    <span class="dt-icon dt-icon-mynotification-upcoming-event"></span>
                  </div>
                  <div class="notifHeader">
                    <div class="notifheading">
                      {{cmsData?.UpcomingAppointment}}
                    </div>
                    <div class="notifDate">
                      {{notifyData.appTimeOff}}
                    </div>
                    <div class="list-text">{{notifyData.apptInfoText}}</div>
                  </div>
                </a>
              </li>
              <li class="notifListItem" id="upcomingServiceAppointment" *ngIf="notifyData.isServiceAppt" (click)="openSerAppt(notifyData.Vin, notifyData)">
                <a href="javascript:void(0)" class="notifListDetails">
                  <div class="notif-read"  *ngIf="notifyData.alertStatus === 'UNREAD'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#3366CC" />
                    </svg>
                  </div>
                  <div class="notif-read" *ngIf="notifyData.alertStatus === 'READ'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#E7F2FA" />
                    </svg>
                  </div>
                  <div class="notifIcon">
                    <span class="dt-icon dt-icon-mynotification-upcoming-event"></span>
                  </div>
                  <div class="notifHeader">
                    <div class="notifheading">
                      {{cmsData?.UpcomingServiceAppointment}}
                    </div>
                    <div class="notifDate">
                      {{notifyData.appTimeOff}}
                    </div>
                    <div class="list-text">{{notifyData?.apptInfoText}}</div>
                  </div>

                </a>
              </li>
              <li class="notifListItem" id="prequalified" *ngIf="notifyData.showPrequalContainer">
                <a href="javascript:void(0)" class="notifListDetails">
                  <div class="notif-read"  *ngIf="notifyData.alertStatus === 'UNREAD'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#3366CC" />
                    </svg>
                  </div>
                  <div class="notif-read" *ngIf="notifyData.alertStatus === 'READ'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#E7F2FA" />
                    </svg>
                  </div>
                  <div class="notifIcon">
                    <span class="dt-icon dt-icon-mynotification-prequalified" *ngIf="preQualifiedAmt > 0"></span>
                    <span class="dt-icon dt-icon-mynotification-prequal-red-icon" *ngIf="preQualifiedAmt <= 0"></span>
                  </div>
                  <div class="notifHeader" (click)="openPrequal(notifyData)">                   
                    <div class="notifheading">{{cmsData?.YouPrequalified}}</div>
                    <div class="notifDate">
                      {{notifyData.preQualTime}}
                    </div>
                    <div class="list-text" *ngIf="preQualifiedAmt > 0">{{cmsData?.Qualify}} <span class="valueofVin"> ${{ notifyData.preQualifiedAmt }}</span>.</div>
                    <div class="list-text" *ngIf="preQualifiedAmt <= 0">{{cmsData?.PrequalNeedMoreInfo}}</div>
                  </div>
                </a>
              </li>
            </ng-container>
          </ng-container>
          <div *ngIf="sortedData.length === 0">
            <div class="notifListItem">
              <div class="notifListDetails">
                <div class="notif-read"  *ngIf="!markallasRead && enableMarkAllAsRead">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#3366CC" />
                  </svg>
                </div>
                <div class="notif-read" *ngIf="markallasRead || !enableMarkAllAsRead">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#E7F2FA" />
                  </svg>
                </div>
                <div class="notifIcon">
                  <span class="dt-icon dt-icon-mynotification-bell"></span>
                </div>

                <div class="notifContent">
                  <div class="listContent">
                    <div class="notifHeader">
                      <div class="notifheading">
                        {{cmsData?.NoNotifcations_Title}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
        <div class="notifcation-view-footer non-mobileView">
        <div >
          <button class="notifcation-view-button" (click)="navigateToNotiications('cta')">
            View Notifications
          </button> 
        </div>
      </div>
      </div>
      <div class="notifcation-view-footer mobileView">
        <div >
          <button class="notifcation-view-button" (click)="navigateToNotiications('cta')">
            View Notifications
          </button> 
        </div>
      </div>
    </div>


 

  </div>