import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnInputComponent } from "./an-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DecimalStringPipeModule, DecimalNumberPipeModule } from "@autonation/dt-an-components-lib/an-pipes";



@NgModule({
  declarations: [AnInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DecimalStringPipeModule,
    DecimalNumberPipeModule,
  ],
  exports: [AnInputComponent],
})
export class AnInputModule {}
