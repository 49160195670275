import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AnInputModule, AnSelectModule } from '@autonation/dt-an-components-lib/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompareBlocksUiComponent } from './compare-blocks-ui/compare-blocks-ui.component';
import { AnSaveFavoritesModule } from '@autonation/dt-an-components-lib/an-save-favorites';
import { CompareVehicleComponent } from './compare-vehicle-modal/compare-vehicle.component';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    CompareVehicleComponent,
    CompareBlocksUiComponent,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AnSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AnInputModule,
    AnSaveFavoritesModule
  ],
  exports: [
    CompareBlocksUiComponent
  ]
})
export class AnComparevehicleModule { }
