import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddVehicleComponent } from './add-vehicle.component';
import { AnInputModule, AnSelectModule } from '@autonation/dt-an-components-lib/forms';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';




@NgModule({
  declarations: [
    AddVehicleComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AnSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AnInputModule 
  ],
  exports: [
    AddVehicleComponent
  ]
})
export class AnAddvehicleModule { }
