import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
 
interface ApptDate {
  dayOfWeek: string;
  month: string;
  day: number;
  checked: boolean;
  value: string;
}

@Component({
  selector: 'an-appt-date',
  templateUrl: './appt-date.component.html',
  styleUrls: ['./appt-date.component.scss']
})
export class ApptDateComponent implements OnInit {

  @Input()
  dateControl!: FormControl;

  @Input()
  dateTitle!: string;

  @Input()
  dates!: string[];

  @Output()
  onChangeDate = new EventEmitter<ApptDate>();

  mappedDates: ApptDate[] = [];

  constructor() { }

  ngOnInit(): void {
    this.mappedDates = this.dates.map((d, i) => {
      const today = new Date();
      const date = new Date(d);
      const dateValues = date.toDateString().split(' ');
      const selectedDate = this.dateControl.value;

      return {
        dayOfWeek: dateValues[0],
        month: dateValues[1],
        day: +dateValues[2],
        checked: !selectedDate && i === 0 ? true :  selectedDate == d,
        value: d
      };
    });
  }

  changeDate(selectedDate: ApptDate) {
    this.mappedDates.forEach(date => {
      if (date.day === selectedDate.day) {
        date.checked = true;
      } else {
        date.checked = false;
      }
    });

    this.onChangeDate.emit(selectedDate);
  }

}
