import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AuthenticationModalLibComponent } from './authentication-modal.component';

interface AnAuthModalElement {
  selector: HTMLElement;
  ref: ComponentRef<AuthenticationModalLibComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AnAuthenticationModalService {
  private renderer!: Renderer2;
  public isOwnershipAppt?: boolean = false;
  public isSRPSearch?: boolean = false;
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createAuthModalContainer();
  }

  triggerAuthModal(isApp?: string): Observable<string> {
    this.isOwnershipAppt = isApp && (isApp == 'appointment') ? true : false;
    this.isSRPSearch = isApp && (isApp == 'srp-search') ? true : false;
    const element = this.createAuthModalElement();
    this.renderer.addClass(document.body, 'fix-height');
    this.openAuthModal(element.selector);
    element.ref.instance.showAuthenticationModal = true;
    return this.handleReturn(element);
  }

  private createAuthModalContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');
    this.renderer.addClass(divOverlayContainer, 'skip-ada-modal-constract');

    const divModalContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divModalContainer, 'id', 'an-auth-modal-container-id');

    this.renderer.appendChild(divOverlayContainer, divModalContainer);
    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createAuthModalElement(): AnAuthModalElement {
    const selector = this.document.createElement('authentication-modal-lib');
    const factory = this.componentFactoryResolver.resolveComponentFactory(AuthenticationModalLibComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getAuthModalContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-auth-modal-container-id');
  }

  private openAuthModal(selector: HTMLElement) {
    const container = this.getAuthModalContainer();

    container
      ? this.renderer.appendChild(container, selector)
      : console.error('"an-auth-modal-container-id" does not exist');
  }

  closeAuthModal(element: AnAuthModalElement) {
    const container = this.getAuthModalContainer();
      if (container) {
        element.ref.instance.showAuthenticationModal = false;
        this.renderer.removeClass(document.body, 'fix-height');
        this.renderer.removeChild(container, element.selector);
      } else {
        console.error('"an-auth-modal-container-id" does not exist');
      }
  }

  private handleReturn(element: AnAuthModalElement) {
    return element.ref.instance.onModalClose.asObservable().pipe(tap((doAuth) => {
      this.closeAuthModal(element);
    }));
  }

}
