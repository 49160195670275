import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppIndexComponent } from './components/app-index/app-index.component';

const routes: Routes = [
  {
    path: '', component: AppIndexComponent

  },
  {
    path: 'todo', component: AppIndexComponent

  },
  {
    path: 'pipes',
    loadChildren: () => import('./pipes/pipes.module').then(m => m.PipesModule)
  },
  {
    path: 'selects',
    loadChildren: () => import('./selects/selects.module').then(m => m.SelectsModule)
  },
  {
    path: 'inputs',
    loadChildren: () => import('./inputs/inputs.module').then(m => m.InputsModule)
  },
  {
    path: 'directives',
    loadChildren: () => import('./directives/directives.module').then(m => m.DirectivesModule)
  },
  {
    path: 'sub-nav',
    loadChildren: () => import('./sub-nav/sub-nav.module').then(m => m.SubNavModule)
  },
  {
    path: 'modals',
    loadChildren: () => import('./modals/modals.module').then(m => m.ModalsModule)
  },
  {
    path: 'appointment',
    loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule)
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
