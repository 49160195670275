import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalNumber'
})
export class DecimalNumberPipe implements PipeTransform {
  transform(value: string | number): number | null {
    if (!value) {
      return null;
    }
    return parseFloat(value.toString().replace(/,/g, ''));
  }
}
