import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AppointmentModalV2Component } from './appointment-modal-v2/appointment-modal-v2.component';
import { SafeSrcPipeModule } from '@autonation/dt-an-components-lib/an-pipes';

@NgModule({
  imports: [
    CommonModule,
    SafeSrcPipeModule
  ],
  declarations: [
    BaseModalComponent,
    CloseIconComponent,
    ConfirmModalComponent,
    AppointmentModalV2Component
  ],
  exports: [
    BaseModalComponent,
    CloseIconComponent,
    ConfirmModalComponent,
    AppointmentModalV2Component
  ]
})
export class AnModalModule { }
