<button (click)="openSave()">Show save toast</button>
<button (click)="openRemove()">Show remove toast</button>
<button (click)="openError()">Show error toast</button>
<button (click)="openLimit()">Show limit toast</button>
<button (click)="openExists()">Show Already Exists toast</button>
<button (click)="openSaveSearchSaveToast()">Show save search toast</button>
<button (click)="openSaveSearchDeleteToast()">Show delete search toast</button>
<button (click)="openSaveSearchErrorToast()">Show save search error toast</button>
<br><br>

<form [formGroup]="toastGroup">
  <div>
    <label> Color: </label>
    <input type="text" formControlName="color">
  </div>
  <div>
    <label> Message: </label>
    <input type="text" formControlName="title">
  </div>
  <div>
    <label> Action text: </label>
    <input type="text" formControlName="actionText">
  </div>
  <button (click)="openCustom()">Show custom toast</button>
</form>

<hr>

<h2>Heart-Icon Module</h2>

<div style="background-color: gray; height: 40px; width: 50px; display: flex; justify-content: center; padding: 5px;">
  <an-heart-icon iconWidth="20px" iconHeight="20px" [state]="heartIconOn" id="heart-icon-id" ariaLabel="Save vehicle"
    (onClickHeartIcon)="clickHeartIcon($event)" (doOldSaveVehicleFlow)="oldSaveVehicle()"></an-heart-icon>
</div>

<hr>

<h2>Auth Modal</h2>

<button (click)="openAuthModal()">Open Auth Modal</button>
<button (click)="openAuthModalAppointment('appointment')">Open Auth Appt Modal</button>
<button (click)="openAuthModalSRPSearch('srp-search')">Open Auth SRP-Search Modal</button>
<button (click)="openNotificationModal(true)">Open Notification Modal</button>
<hr>

<button (click)="openVerifyEmailModal()">Open Verify Email Modal</button>
<button (click)="openSRPVerifyEmailModal()">Open SRP Verify Email Modal</button>

<hr>

<h2>Save Favorites</h2>

<div class="vehicles-container">
  <div class="vehicle" *ngFor="let vehicle of vehicles; let pos = index">
    <an-save-favorites [vin]="vehicle.Vin" appName="vdp" [vehicleSaved]="vehicle.vehicleSaved" [ariaLabel]="'Ford Mustang'"
      (onLoading)="setLoading($event)" (doOldSaveVehicleFlow)="oldSaveVehicle()"></an-save-favorites>
  </div>
</div>

<br>

<div class="loading" *ngIf="loading">
  Loading ...
</div>

<hr>

<h1> Modal </h1>

<button (click)="openModal()">open Appointment modal</button>

<button (click)="openModalHours()">open hours modal</button>

<button (click)="openAddVehicleModal()">open add vehicle modal</button>

<button (click)="openSRPCompareVehicleModal()">open SRP compare vehicle modal</button>

<button (click)="loadVDPCompareVehicleSection()">Load VDP compare vehicle Section</button>

<button (click)="openFinishAddVehicleModal()">open Finish add vehicle modal</button>

<button (click)="openModalConfirm()">open confirm cancel modal</button>

<lib-compare-blocks-ui></lib-compare-blocks-ui>
