<div class="an-input-email form-group">
  <label [for]="'id-' + label" class="an-input-email__label"> {{ label }} </label>
  <input type="text" maxlength="50" class="an-input-email__control form-control" [id]="'id-' + label"
    [placeholder]="placeholder" [mask]="mask || ''" [formControl]="emailControl">
  <a href="javascript:void(0)" class="an-input-email__clear" *ngIf="hasError && emailControl.enable"
    (click)="clear()"></a>
  <an-tooltip [tooltipTitle]="tooltipTitle" [tooltipContent]="tooltipContent" [tooltipMarginLeft]="tooltipMarginLeft"
    [tooltipArrowMarginLeft]="tooltipArrowMarginLeft" [tooltipMarginBottom]="tooltipMarginBottom" *ngIf="emailControl.disabled">
    <a href="javascript:void(0)" class="an-input-email__tooltip"></a>
  </an-tooltip>
  <div class="an-input-email__error-message" *ngIf="hasError">
    {{ emailControl.hasError('required') ? requiredErrorMessage : invalidErrorMessage }}
  </div>
</div>