import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss']
})
export class InputEmailComponent implements OnInit {

  @Input()
  label!: string;

  @Input()
  placeholder!: string;

  @Input()
  requiredErrorMessage!: string;

  @Input()
  invalidErrorMessage!: string;

  @Input()
  type!: string;

  @Input()
  mask!: string;
  
  @Input()
  emailControl!: FormControl;

  @Input()
  tooltipTitle!: string;

  @Input()
  tooltipContent!: string;

  tooltipMarginLeft!: string;
  tooltipArrowMarginLeft!: string;
  tooltipMarginBottom!: string;

  @Output()
  onClickHelp = new EventEmitter();

  ngOnInit(): void {
    this.onResize(window.innerWidth);
  }

  get hasError() {
    return this.emailControl.errors && !this.emailControl.pristine;
  }

  clear() {
    this.emailControl.setValue('');
    this.emailControl.markAsPristine()
  }

  clickHelp() {
    this.onClickHelp.emit();
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {

    if (width < 904) {
      this.tooltipMarginLeft = '-320px'
      this.tooltipArrowMarginLeft = '130px';
      this.tooltipMarginBottom = '7px'
    } else {
      this.tooltipMarginLeft = '-200px'
      this.tooltipArrowMarginLeft = '10px';
      this.tooltipMarginBottom = '7px'
    }
  }

}
