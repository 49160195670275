<an-base-modal (onCloseModal)="closeModal()">
  <div class="confirm-modal" [class]="data?.modalType">
    <div class="confirm-modal__title">
      {{ data?.header }}
    </div>
    <div class="confirm-modal__content">
      {{ data?.subText }}
    </div>
    <div class="confirm-modal__cta">
      <button class="cta cta--primary" (click)="onClickButton1.emit(); closeModal()"> {{ data?.buttonText }} </button>
      <button class="cta cta--secondary" (click)="onClickButton2.emit()"> {{ data?.buttonText2 }} </button>
    </div>
  </div>
</an-base-modal>