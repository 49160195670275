<div class="an-state form-group">
  <label [for]="'id-' + stateLabel" class="an-state__label"> {{ stateLabel }} </label>
  <select class="an-state__control form-control" [id]="'id-' + stateLabel" [formControl]="stateControl"
    [ngClass]="{'an-state--selected': stateControl.value != 'State' && stateControl.errors == null}">
    <option class="an-default" value="State"> Select </option>
    <option *ngFor="let state of states" [value]="state.value">
      {{ state.description }}
    </option>
  </select>
  
</div>