import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationModalLibComponent } from './notification-modal.component';
import { SafeUrlPipeModal } from './pipes/safe-url.pipe';
import { AnNotificationModalService } from './notification-modal.service';
import { AnModalModule } from '@autonation/dt-an-components-lib/an-modal';




@NgModule({
  declarations: [
    NotificationModalLibComponent,
    SafeUrlPipeModal
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AnModalModule
    
  ],
  exports: [
    NotificationModalLibComponent
  ],
  providers: [DatePipe]
})
export class NotificationModule { 
  constructor(private notificationService: AnNotificationModalService) {
    console.log('__OPEN__NOTIFICATION__MODAL__');
    this.notificationService.triggerNotificationModal(null,true)
  }
}
