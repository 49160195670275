import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeUrlPipeModal' })
export class SafeUrlPipeModal implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(url: string) {
    return this.sanitized.bypassSecurityTrustResourceUrl(url);
  }
}
