import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FavoriteVehicles, RemoveVehicle, SaveVehicle } from '../models/favorite-vehicles';
import { AnToastService } from '@autonation/dt-an-components-lib/an-toast';
import { isDevMode } from '@angular/core';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  favoriteVehicles!: FavoriteVehicles;
  favoriteVehiclesLoaded = new BehaviorSubject<boolean>(false);
  initFlag: boolean = false;
  API_URL = {
    LOCAL: 'http://localhost:4000',
    BASE: '/my-account/my-favorite/api/MyGarage',
    GET: '/FavoriteVehicles',
    SAVE: '/saveFavoriteVehicle',
    REMOVE: '/removeFavorite',
  };

  constructor(
    private http: HttpClient,
    private toastService: AnToastService,
    private stateService: StateService
  ) {


    if (
          // (this.stateService.myGarageFavorites.EnableFavorites || this.stateService.myGarageFavorites.EnableMyGarageFavorites )
          // && !this.initFlag
          !this.initFlag
       )
       {
        if (document.getElementById('ANUserId') && (document.getElementById('ANUserId') as any).value !== '' && (document.getElementById('ANUserId') as any).value) {

          this.loadFavoritesForLoggedInUser();
        } else {
          this.favoriteVehiclesLoaded.next(false);
        }
    }
  }

  loadFavoritesForLoggedInUser() {
    this.getVehicles().subscribe({
      next: favoriteVehicles => {
        if (favoriteVehicles.Success) {
          this.favoriteVehicles = favoriteVehicles;
          this.dispatchFavoritesVehicleCountEvent(this.favoriteVehicles.Vehicles.favoriteVehiclesData.count);

          if (localStorage.getItem('auth0Login') && localStorage.getItem('trigger_save_vehicle_method')) {
            const savingVehicleVin = localStorage.getItem('trigger_save_vehicle_method') || '';

            // Check if clicked vin is already favorite or not
            let isExists = this.isVehicleSaved(savingVehicleVin);
            if (!isExists) {
              this.saveVehicleFromLocalStorage(savingVehicleVin);
            } else {
              localStorage.removeItem('trigger_save_vehicle_method');
              this.toastService.alreadyExists().subscribe(_ => {
                this.dispatchViewSavedVehicleEvent();
                document.location.href = '/my-account/my-favorite';
              });
              this.favoriteVehiclesLoaded.next(true);
            }

          } else {
            this.favoriteVehiclesLoaded.next(true);
          }

        } else {
          console.log(favoriteVehicles);
          this.toastService.error();
        }
      },
      error: error => {
        console.log(error);
        this.toastService.error({
          title: 'Error getting Saved Vehicles.'
        });
      }
    });
  }

  saveVehicle(vin: string): Observable<SaveVehicle> {
    const url = this.buildURL(this.API_URL.SAVE);
    return this.http.post<SaveVehicle>(url, { vin });
  }

  deleteVehicle(vin: string): Observable<RemoveVehicle> {
    const url = this.buildURL(this.API_URL.REMOVE);
    return this.http.post<RemoveVehicle>(url, { vin });
  }

  getVehicles(): Observable<FavoriteVehicles> {
    return this.http.get<FavoriteVehicles>(this.buildURL(this.API_URL.GET));
  }

  saveVehicleFromLocalStorage(vin: any) {
    localStorage.removeItem('trigger_save_vehicle_method');
    if (this.isAllowToSave()) {
      this.saveVehicle(vin).subscribe({
        next: response => {
          if (response.Success) {
            this.toastService.save().subscribe(_ => {
              this.dispatchViewSavedVehicleEvent();
              document.location.href = '/my-account/my-favorite';
            });
          } else {
            this.toastService.error();
          }
          this.loadFavoritesForLoggedInUser();
        },
        error: _ => {
          this.toastService.error();
          this.loadFavoritesForLoggedInUser();
        },
        complete: () => { }
      });
    } else {
      this.toastService.limit();
    }
  }

  isVehicleSaved(vin: string): boolean {
    return this.favoriteVehicles?.Vehicles.favoriteVehiclesData.favoriteVehicles
      .find(vehicle => vehicle.vin === vin)
      ? true : false;
  }

  isAllowToSave(): boolean {
    return this.favoriteVehicles?.Vehicles.favoriteVehiclesData.count <
      this.favoriteVehicles?.Vehicles.maxFavoriteVehiclesLimit;
  }

  updateVehiclesCount(increase: boolean, vin: string, count: number): void {
    if (increase) {
      this.favoriteVehicles.Vehicles.favoriteVehiclesData.favoriteVehicles.push({ vin: vin });
    } else {
      this.favoriteVehicles.Vehicles.favoriteVehiclesData.favoriteVehicles = this.favoriteVehicles.Vehicles.favoriteVehiclesData.favoriteVehicles.filter(vehicle => vehicle.vin != vin);
    }

    this.favoriteVehicles.Vehicles.favoriteVehiclesData.count = this.favoriteVehicles.Vehicles.favoriteVehiclesData.favoriteVehicles?.length ?? 0;

    console.log(this.favoriteVehicles.Vehicles.favoriteVehiclesData);
    // this.dispatchFavoritesVehicleCountEvent(this.favoriteVehicles.Vehicles.favoriteVehiclesData.count);
    this.dispatchModifyCookieForFavoritesVehicleCountEvent(increase);
  }

  isUserLoggedIn(): boolean {
    const inputValue = (<HTMLInputElement>document.getElementById('ANUserId')) ? (<HTMLInputElement>document.getElementById('ANUserId')).value : '';
    return inputValue !== '';
  }

  isActiveSession() {
    const inputValue = (<HTMLInputElement>document.getElementById('ANUserId')) ? (<HTMLInputElement>document.getElementById('ANUserId')).value : null;
    return inputValue;
  }

  dispatchFavoritesVehicleCountEvent(count: any): void {
    document.dispatchEvent(new CustomEvent('FAVORITE_VEHICLES_COUNT', {
      detail: count
    }));
  }

  dispatchModifyCookieForFavoritesVehicleCountEvent(increase: boolean): void {
    document.dispatchEvent(new CustomEvent('FAVORITE_VEHICLES_COUNT_MODIFY_COOKIE', {
      detail: increase ? 'increase' : 'decrease'
    }));
  }

  dispatchViewSavedVehicleEvent(): void {
    document.dispatchEvent(new CustomEvent('FAVORITE_VEHICLES_CLICK_VIEW_SAVED_VEHICLE'));
  }

  private buildURL(endpoint: string): string {
    let url = this.API_URL.BASE + endpoint;
    return (isDevMode() || document.location.origin == 'https://cd.dockerexamples.localhost') ? this.API_URL.LOCAL + url : url;
  }

}
