import { Component, EventEmitter, HostListener, OnInit, Output, } from '@angular/core';
import { verifyEmailModalImage } from './verify-email-modal.constants';
import { AnVerifyEmailModalService } from './verify-email-modal.service';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'verify-email-modal-lib',
   templateUrl: './verify-email-modal.component.html',
   styleUrls: ['./verify-email-modal.component.scss']
})
export class VerifyEmailModalLibComponent implements OnInit {
   showVerifyEmailModal: boolean = false;
   @Output() onModalClose: EventEmitter<string> = new EventEmitter<string>();
   verifyEmailModalImage = verifyEmailModalImage;
   resentEmailFired: boolean = false;
   showSpinner: boolean = false;
   isSRPSearch?: boolean = false;

   constructor(private verifyEmailModalService: AnVerifyEmailModalService) {

   }
   /* istanbul ignore next */
   @HostListener('window:keydown', ['$event'])
   onKeyDownHandler(event: KeyboardEvent) {
      if (event.key === 'Tab' && this.showVerifyEmailModal === true) {
         event.preventDefault();
         this.controlTabNavigation(event);
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
         if (this.isSRPSearch) {
            event.preventDefault();
            this.closeVerifyEmailModal();
         }
      }
   }

   ngOnInit() {
      this.isSRPSearch = this.verifyEmailModalService.isSRPSearch;
      console.log('this.isSRPSearch - ', this.isSRPSearch);
    }

   controlTabNavigation(event: any) {
      var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
      let modalBox = document.getElementById('verify-email-modal-centents');
      if (modalBox) {
         const keyboardfocusableElements = Array.from(modalBox.querySelectorAll(focusableElementsString));
         const numberOfFocusableItems = keyboardfocusableElements.length;
         const firstFocusableElement = keyboardfocusableElements[0];
         const lastFocusableElement = keyboardfocusableElements[numberOfFocusableItems - 1];
         const focusedItem = document.activeElement;
         const focusedItemIndex = focusedItem ? keyboardfocusableElements.indexOf(focusedItem) : -1;

         if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
               (lastFocusableElement as HTMLElement).focus();
            } else {
               (keyboardfocusableElements[focusedItemIndex - 1] as HTMLElement).focus();
            }
         } else {
            if (document.activeElement === lastFocusableElement) {
               (firstFocusableElement as HTMLElement).focus();
            } else {
               (keyboardfocusableElements[focusedItemIndex + 1] as HTMLElement).focus();
            }
         }
      }
      event.preventDefault();
   }

   logOut() {
      try {
         if ((window as any).auth) {
            (window as any).auth.logoutAuth();
         }
      } catch (e) {
         console.log(e);
      }
   }

   resendEmailConfirmation() {
      this.showSpinner = true;
      this.verifyEmailModalService.verifyEmail().subscribe(response => {
         this.showSpinner = false;
         if (response.Success) {
            this.resentEmailFired = true;
            setTimeout(() => {
               this.resentEmailFired = false;
            }, 10000);
            // 10 sec value will be dynamic and come from feature manager, once BE is implemented for that key, here we have to change and make it dynamic
         } else {
            console.log('resendEmail error - ', response);
            this.resentEmailFired = false;
         }
      }, (error) => {
         this.showSpinner = false;
         console.log('resendEmail error - ', error);
         this.resentEmailFired = false;
      });
   }

   closeVerifyEmailModal(doAuth?: string) {
      const returnFlag = doAuth ? doAuth : '';
      this.showVerifyEmailModal = false;
      this.onModalClose.emit(returnFlag);
   }

   refresh() {
      try {
         if ((window as any).auth) {
            (window as any).auth.refreshAuth0();
         }
      } catch (e) {
         console.log(e);
      }
   }
}
