import { Component, EventEmitter, HostListener, Input, Optional, Output, Self } from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl } from "@angular/forms";
import { OnInit, OnDestroy } from "@angular/core";
import { DecimalStringPipe, DecimalNumberPipe } from "@autonation/dt-an-components-lib/an-pipes";
import { UpperCasePipe } from "@angular/common";

@Component({
  selector: "an-input",
  templateUrl: "./an-input.component.html",
  styleUrls: ["./an-input.component.scss"],
  providers: [DecimalStringPipe, DecimalNumberPipe, UpperCasePipe],
})
export class AnInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  constructor(
    @Self() @Optional() public parent: NgControl,
    private decimalStringPipe: DecimalStringPipe,
    private decimalNumberPipe: DecimalNumberPipe,
    private upperCasePipe: UpperCasePipe
  ) {
    this.parent.valueAccessor = this;
  }

  static nextId = 0;
  id = `an-input-${AnInputComponent.nextId++}`;
  value!: string;

  @Input()
  label!: string;
  @Input()
  labelComment!: string;
  @Input()
  name!: string;
  @Input()
  placeHolder = "";
  @Input()
  defaultValue!: string;
  @Input()
  numbersOnly = false;
  @Input()
  maxlength!: number;
  @Input()
  minlength!: number;
  @Input()
  autocomplete = true;
  @Input()
  titlecase = false;
  @Input()
  enableCustomcss?: boolean;
  private _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = value;
  }
  @Output()
  blur: EventEmitter<void> = new EventEmitter();

  inputControl = new FormControl("");

  touched = false;
  disabled = false;

  @Input()
  lockedIcon!: boolean;

  onTouched = () => {
    return;
  };

  onChange = (value: string | number | null) => {
    return value;
  };

  onBlur() {
    this.blur.emit();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.inputControl.valueChanges.subscribe((val) => {
      // Transform all letters to capital
      if (this.titlecase) {
        val = this.upperCasePipe.transform(val);
        this.inputControl.patchValue(val, { onlySelf: true, emitEvent: false });
      }
      // Transform to decimal format
      if (this.numbersOnly) {
        this.inputControl.patchValue(this.decimalStringPipe.transform(val), {
          onlySelf: true,
          emitEvent: false,
        });
        this.onChange(this.decimalNumberPipe.transform(val));
      } else {
        this.onChange(val);
      }
    });
  }

  writeValue(value: string): void {
    this.inputControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: () => string) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputControl.disable({ onlySelf: true, emitEvent: false });
    }
    else {
      this.inputControl.enable({ onlySelf: true, emitEvent: false });
    }
  }

  @HostListener("keydown", ["$event"])
  eventHandler(event: { keyCode: number }) {
    return this.validateNumbers(event.keyCode);
  }

  validateNumbers(keyCode: number) {
    if (this.numbersOnly) {
      return (
        (keyCode < 32 && keyCode !== 16 && keyCode !== 38 && keyCode !== 42) || // special chars
        (keyCode >= 48 && keyCode <= 57) || // digits
        (keyCode >= 96 && keyCode <= 105) // numpad digits
      );
    }
    return true
  }

  get errorMsg() {
    if(this.parent.control?.errors) {
      const keys = Object.keys(this.parent.control?.errors);
      if (keys.length) {
        return this.parent.control.errors[keys[0]]?.msg;
      }
    }
    
  }
}
