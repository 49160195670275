<div class="an-input-container">
  <label [class.show-asterisk]="required" [for]="id"
    >{{ label }} <span>{{ labelComment }}</span></label
  ><br />

  <input
    class="an-input"
    [class.error]="parent.control?.errors && parent.control?.dirty"
    [ngClass]="{'locked-icon': lockedIcon,'enableCustomcss': enableCustomcss}"
    [name]="name"
    [id]="id"
    [placeholder]="placeHolder"
    [formControl]="inputControl"
    [autocomplete]="autocomplete ? 'true' : 'off'"
    [maxlength]="maxlength"
    [minlength]="minlength"
    (blur)="onBlur()"
  />
  <span class="validation">
    <i *ngIf="inputControl.value && parent.control?.dirty && !inputControl.errors && !parent.control?.errors" class="valid-icon">
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0.00390625C3.14005 0.00390625 0 3.14395 0 7.00391C0 10.8639 3.14005 14.0039 7 14.0039C10.86 14.0039 14 10.8639 14 7.00391C14 3.14395 10.86 0.00390625 7 0.00390625Z" fill="#108634"/>
        <path d="M10.5472 5.5213L6.75548 9.3129C6.64172 9.42665 6.4924 9.4839 6.34308 9.4839C6.19376 9.4839 6.04443 9.42665 5.93068 9.3129L4.03488 7.4171C3.80673 7.18906 3.80673 6.82034 4.03488 6.5923C4.26292 6.36415 4.63153 6.36415 4.85968 6.5923L6.34308 8.0757L9.72238 4.6965C9.95042 4.46835 10.319 4.46835 10.5472 4.6965C10.7752 4.92455 10.7752 5.29315 10.5472 5.5213Z" fill="white"/>
        </svg>        
    </i>
  </span>
  <span class="validation">
    <i *ngIf="parent.control?.errors && parent.control?.dirty" class="invalid-icon">
      <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m11.9495 2.05c-.979-.97889-2.22628-1.645507-3.58413-1.915546s-2.76528-.13137363-4.04432.398461c-1.27904.529835-2.37225 1.427045-3.14139 2.578175-.769135 1.15112-1.17966 2.50447-1.17966 3.88891s.410525 2.73779 1.17966 3.8889c.76914 1.1511 1.86235 2.0484 3.14139 2.5782s2.68647.6685 4.04432.3985c1.35785-.2701 2.60513-.9367 3.58413-1.9156.6501-.65 1.1658-1.4217 1.5176-2.27105.3518-.84932.5329-1.75963.5329-2.67895 0-.91931-.1811-1.82962-.5329-2.67895-.3518-.84932-.8675-1.62103-1.5176-2.27105zm-2.45001 6.72c.04996.04677.08978.1033.11701.16608.02722.06279.04127.13049.04127.19892s-.01405.13614-.04127.19892c-.02723.06279-.06705.11931-.11701.16608-.04672.04634-.10212.083-.16304.10789-.06092.02488-.12615.03749-.19195.03711-.13118-.00055-.25688-.05263-.35001-.145l-1.79499-1.795-1.77 1.795c-.09314.09237-.21884.14445-.35.145-.06581.00038-.13104-.01223-.19196-.03711-.06092-.02489-.11632-.06155-.16304-.10789-.09313-.09368-.1454-.2204-.1454-.3525 0-.13209.05227-.25882.1454-.3525l1.77-1.795-1.77-1.77c-.08192-.09565-.12472-.21869-.11986-.34452.00486-.12584.05703-.24521.14607-.33426.08905-.08905.20842-.14121.33426-.14607s.24887.03794.34453.11985l1.77 1.77 1.77-1.77c.04629-.04793.10156-.08627.16267-.11284.06112-.02657.12686-.04084.19349-.04201.06662-.00116.13282.01082.19482.03524.062.02443.11858.06082.16651.10711.04794.04629.08628.10157.11285.16268s.04084.12686.042.19348c.00116.06663-.01081.13283-.03523.19483-.02443.062-.06082.11858-.10712.16651l-1.795 1.77z" fill="#d0021b"/></svg>
    </i>
  </span>
  <div>
    <i *ngIf="parent.control?.errors && errorMsg" class="error-msg">
      {{ errorMsg }}
    </i>
  </div>
</div>
