import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Optional,
  Self,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";

import { Item } from "./select.interface";

@Component({
  selector: "an-select",
  templateUrl: "./an-select.component.html",
  styleUrls: ["./an-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnSelectComponent implements ControlValueAccessor {
  static nextId = 0;
  id = `an-select-${AnSelectComponent.nextId++}`;
  private _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = value;
  }
  @Input() enableCustomcss?: boolean;
  @Input() placeholder!: string;
  @Input() isDefaultNotNeeded?: boolean;
  @Input() idValue?: string;
  @Input() label!: string;
  @Input() showAsterisk?:boolean;
  _items!: Item[];
  @Input() set items(list: Item[]) {
    this._items = list;
    this.cd.detectChanges();
  }
  
  @Input()
  lockedIcon!: boolean;

  constructor(
    private readonly cd: ChangeDetectorRef,
    @Self() @Optional() public parent: NgControl,
  ){
    this.parent.valueAccessor = this;
  }

  selectListControl = new FormControl("");

  writeValue(value: string): void {
    this.selectListControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string) => void) {
    this.selectListControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  compareWith(o1: Item, o2: Item): boolean {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  } 

  onTouched: () => void = () => {};

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.selectListControl.disable({ onlySelf: true, emitEvent: false });
    }
    else {
      this.selectListControl.enable({ onlySelf: true, emitEvent: false });
    }
  }

  setSelectedOptionClass(anSelectEle: HTMLSelectElement){ 
    const selectedIndex = anSelectEle.selectedIndex;
    const selectedOption = anSelectEle.options[selectedIndex];
    const selectedoptionClassName = selectedOption.className;
    if(!anSelectEle.classList.contains(selectedoptionClassName)){
      if(selectedoptionClassName === 'defaultSelect'){
        anSelectEle.classList.remove('optionSelected');
      }else{
        anSelectEle.classList.remove('defaultSelect');
      }
    anSelectEle.classList.add(selectedoptionClassName);
    }
  }

  get errorMsg() {
    if(this.parent.control?.errors) {
      const keys = Object.keys(this.parent.control?.errors);
      if (keys.length) {
        return this.parent.control.errors[keys[0]]?.msg;
      }
    }
    
  }

}
