
export const UDO = Object.freeze({
  NotSet: '(not set)',
});

export enum CategoryL2 {
  MyFavorites = 'My Favorites',
}

export interface ITealiumSettings {
  TealiumAccount: string;
  TealiumENV: string;
  TealiumProfile: string;
};

export interface ITealiumRequest {
  category_l1?: string;
  category_l2?: string;
  category_l3?: string;
  category_l4?: string;
  event: string;
  event_category: string;
  event_action: string;
  event_label: string;
  event_value: number;
  pagegroup: string;
}

export enum TealiumEvent {
  LogIn = 'log_in_click',
  SignUp = 'sign_up_click',
  ExitModal = 'exited_out',
  ErrorSaveVehicle = 'error_save_vehicle',
  UndoRemovedFromFavorites = 'undo_removed_from_favorites',
  FavReachedSaveVehicle = 'favorites_reached_save_vehicle'
}

export enum TealiumEventCategory {
  UserActivityType = 'UserActivityType',
}


export const tealiumEventAction = (pageGroup: string) => `UserActivityType_${pageGroup}|||Click`

export enum TealiumEventLabel {
  LogInClick = 'Log In Click',
  SignUpClick = 'Sign Up Click',
  ExitedOut = 'Exited Out',
  ErrorSaveVehicle = 'Error Save Vehicle',
  FavReachedSaveVehicle = 'Favorites Reached Save Vehicle',
  UndoRemovedFromFavorites = 'Undo Removed From Favorites'
}
