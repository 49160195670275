<div #modal aria-hidden="true" class="new-modal" role="dialog" *ngIf="showVerifyEmailModal">
  <div class="new-modal-dialog">
    <div id="verify-email-modal-centents" class="new-modal-content" (click)="$event.stopPropagation()">
      <ng-content></ng-content>

      <button *ngIf="isSRPSearch" #closeModalRef aria-label="Close dialog" class="new-modal-close" [ngClass]="{'new-modal-close-appt': isSRPSearch}" data-dismiss="modal" (click)="closeVerifyEmailModal()">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.27755 7.04376C8.61754 7.38391 8.61754 7.93349 8.27755 8.27365C8.10795 8.44326 7.88532 8.52845 7.66253 8.52845C7.43989 8.52845 7.21725 8.44326 7.04766 8.27365L4.43423 5.66005L1.82079 8.27365C1.65122 8.44326 1.42857 8.52845 1.20595 8.52845C0.983171 8.52845 0.760523 8.44326 0.590931 8.27365C0.25094 7.93349 0.25094 7.38391 0.590931 7.04376L3.2045 4.43036L0.590931 1.8169C0.25094 1.47675 0.25094 0.92717 0.590931 0.587031C0.931042 0.247031 1.48063 0.247031 1.82079 0.587031L4.43423 3.20061L7.04766 0.587031C7.38782 0.247031 7.93739 0.247031 8.27755 0.587031C8.61754 0.927179 8.61754 1.47676 8.27755 1.8169L5.66395 4.43036L8.27755 7.04376Z"
            fill="#949494" />
        </svg>
      </button>

      <div class="verify-email-modal-body">
        <div class="tableHeader">
          Verify Your Account
        </div>
        <div class="image-sec">
          <img [src]="verifyEmailModalImage | safeUrlPipeModal" />
        </div>
        <div class="verify-email-text">
          Take your experience to the next level! Please check your email to verify your account.
        </div>
        <button class="verify-email-modal-btn sign-out-btn" (click)="logOut()">Sign Out</button>
        <button class="verify-email-modal-btn create-account-btn" (click)="resendEmailConfirmation()"
          *ngIf="!resentEmailFired">
          <span class="spinner-image" *ngIf="showSpinner">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M6.99382e-07 8C8.37707e-07 6.41775 0.469193 4.87103 1.34824 3.55544C2.2273 2.23984 3.47672 1.21446 4.93853 0.608963C6.40034 0.00346195 8.00888 -0.154965 9.56072 0.153717C11.1126 0.462399 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16L8 14.8C9.34491 14.8 10.6596 14.4012 11.7779 13.654C12.8961 12.9068 13.7677 11.8448 14.2824 10.6022C14.7971 9.35971 14.9317 7.99246 14.6693 6.67339C14.407 5.35431 13.7593 4.14267 12.8083 3.19167C11.8573 2.24068 10.6457 1.59304 9.32662 1.33066C8.00754 1.06828 6.64029 1.20294 5.39775 1.71762C4.15522 2.23229 3.0932 3.10387 2.34601 4.22212C1.59881 5.34038 1.2 6.65509 1.2 8L6.99382e-07 8Z"
                fill="white" />
            </svg>
          </span>
          Resend Email Confirmation</button>
        <button class="verify-email-modal-btn disabled-btn" *ngIf="resentEmailFired">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M8 0.5C3.86433 0.5 0.5 3.86433 0.5 8C0.5 12.1357 3.86433 15.5 8 15.5C12.1357 15.5 15.5 12.1357 15.5 8C15.5 3.86433 12.1357 0.5 8 0.5Z"
                fill="#BCBCBC" />
              <path
                d="M11.8014 6.4108L7.73888 10.4732C7.617 10.5951 7.45702 10.6564 7.29703 10.6564C7.13704 10.6564 6.97705 10.5951 6.85517 10.4732L4.82396 8.44201C4.57951 8.19768 4.57951 7.80263 4.82396 7.5583C5.06829 7.31385 5.46323 7.31385 5.70767 7.5583L7.29703 9.14765L10.9177 5.52708C11.162 5.28264 11.557 5.28264 11.8014 5.52708C12.0458 5.77142 12.0458 6.16635 11.8014 6.4108Z"
                fill="#FAFAFA" />
            </svg>
            Confirmation Sent
          </span>
        </button>
        <div class="verify-confirm" (click)="refresh()">
          I have already verified my account
        </div>
      </div>
    </div>
  </div>
</div>