import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnToastComponent } from './an-toast.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    AnToastComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    AnToastComponent
  ]
})
export class AnToastModule { }
