import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { AnHeartIconComponent } from "./an-heart-icon.component";


@NgModule({
  declarations: [
    AnHeartIconComponent
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  exports: [
    AnHeartIconComponent
  ]
})
export class AnHeartIconModule { }
