import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompareVehicleComponent } from './compare-vehicle-modal/compare-vehicle.component';

interface ICompareVehicleComponent {
  selector: HTMLElement;
  ref: ComponentRef<CompareVehicleComponent>;
}

@Injectable({
  providedIn: 'root'
})

export class AnCompareVehicleService {
  private renderer!: Renderer2;

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
    private http: HttpClient
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createCompareVehicleModalContainer();
  }


  triggerCompareVehicleModal(reqObj: any): Observable<string> {
    const element = this.createCompareVehcileModalElement();
    this.renderer.addClass(document.body, 'fix-height');
    this.openCompareVehicleModal(element.selector, reqObj);
    element.ref.instance.showCompareVehicleModal = true;
    return this.handleReturn(element);
  }


  private createCompareVehicleModalContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    // this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');

    const divModalContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divModalContainer, 'id', 'an-compare-vehicle-modal-container-id');

    this.renderer.appendChild(divOverlayContainer, divModalContainer);
    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createCompareVehcileModalElement(): ICompareVehicleComponent {
    const selector = this.document.createElement('compare-vehicle-modal-lib');
    const factory = this.componentFactoryResolver.resolveComponentFactory(CompareVehicleComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getCompareVehicleModalContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-compare-vehicle-modal-container-id');
  }

  private openCompareVehicleModal(selector: HTMLElement, reqObj: any) {
    const container = this.getCompareVehicleModalContainer();

    container ? this.renderer.appendChild(container, selector) : console.error('"an-compare-vehicle-modal-container-id" does not exist');

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("__LOAD__VEHICLE__COMPARE__SECTION__", {
        detail: reqObj,
      }));
    });
  }

  closeCompareVehicleModal(element: ICompareVehicleComponent, doAuth: any) {
    const container = this.getCompareVehicleModalContainer();
    if (container) {
      element.ref.instance.showCompareVehicleModal = false;
      this.renderer.removeClass(document.body, 'fix-height');
      this.renderer.removeChild(container, element.selector);
    } else {
      console.error('"an-compare-vehicle-modal-container-id" does not exist');
    }
  }

  private handleReturn(element: ICompareVehicleComponent) {
    return element.ref.instance.onModalClose.asObservable().pipe(tap((doAuth) => {
      this.closeCompareVehicleModal(element, doAuth);
    }));
  }
}
