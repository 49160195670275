<div class="outer-div-wrapper" [ngClass]="{'non-modal': viewMode == 'non-modal', 'modal-view': viewMode == 'modal'}">
    <div class="outer-div modal-content-middle-section" id="compareVehicles">
        <div class="static-div info-section py-0" [ngClass]="{'vehicle-header': i === 0, 'similar-vehicles': i > 0 }"
            *ngFor="let vehicle of carsArray; let i = index;">
            <!--[ngClass]="{'vehicle-header': i === 0, 'similar-vehicles-current': i === 1, 'similar-vehicles': i > 1 }"-->
            <div class="car-slider-header" *ngIf="i > 0">
                <div class="img-container" *ngIf="vehicle.ThumbImage !== ''">
                    <img [src]="vehicle.ThumbImage" alt="car">

                    <!-- Heart Icon commentted out for bug #450036 No Dynamic logic is mentioned in the bug for displaying heart icon-->

                    <!-- <an-save-favorites class="an-save-favorites" [vin]="vehicle.Vin"
                        [vehicleSaved]="vehicle.IsSaved || false" [ariaLabel]="vehicle.Vin" appName="vdp"
                        iconWidth="24px" iconHeight="24px">
                    </an-save-favorites> -->

                    <!--for old vehicle save flow (doOldSaveVehicleFlow)="saveVehicle(vehicle.Vin)"-->
                </div>
                <p class="car-slider-title header-title">
                    {{vehicle.VehicleYear}} {{vehicle.VehicleMake}} {{vehicle.VehicleModel}}
                </p>
                <p class="car-slider-secondary-title header-secondary-title">
                    {{vehicle.VehicleTrim}}
                </p>
                <div class="car-slider-condition-container">
                    <div class="vehicle-stocktype" *ngIf="vehicle.Condition !== ''"
                        [ngStyle]="{'background-color': vehicle.StockBgColor}">
                        <span>{{vehicle.Condition}}</span>
                    </div>
                    <div class="fuel-type" *ngIf="isMobileView()" [innerHTML]="setBadge(vehicle.Fuel) | safeHtml">
                    </div>
                    <div class="car-slider-mileage" *ngIf="vehicle.Condition !== 'NEW' && vehicle.Mileage">
                        <p class="car-slider-mileage-text">Milage {{vehicle.Mileage}}</p>
                    </div>
                </div>
                <p class='vdw'>
                    <a class="car-slider-link" *ngIf="vehicle.Vin !== ''" [href]="tileLinkUrl(vehicle)">View details</a>
                </p>
            </div>

            <!--Car Info titles-->
            <div class="car-slider-car-info header-section" *ngIf="i === 0"
                [ngClass]="{'car-info-text-non-modal': viewMode == 'non-modal'}">
                <p class="car-info-text static-div-text mspr black">
                    <!-- <span class="car-info-text-title price"
                        *ngIf="vehicle.FormattedPrice != '$'">{{vehicle.FormattedPrice}}</span>
                    <span class="car-info-text-title price" *ngIf="vehicle.FormattedPrice == '$'">
                        <a class="phonenum-link" href="tel:{{vehicle?.StorePhoneNumber}}"
                            tabindex="0">{{vehicle?.StorePhoneNumber}}</a>
                    </span> -->
                </p>
                <p class="car-info-text static-div-text enginetext black" *ngIf="showEngine">
                    <span class="car-info-text-title">{{vehicle.Engine}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-Engine-Content">
                                <span class="tooltiptext" id="myEngineTooltip">Engine</span>
                                <img alt="tooltipIcon" class="question-mark" style=" vertical-align: none !important;"
                                    id="msrp-engine-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                </p>
                <p class="car-info-text static-div-text black" *ngIf="showTransmission">
                    <span class="car-info-text-title">{{vehicle.Transmission}}</span>
                </p>
                <p class="car-info-text static-div-text black" *ngIf="showDriveType">
                    <span class="car-info-text-title">{{vehicle.VehicleDriveType}}</span>
                </p>
                <!-- <p class="car-info-text static-div-text black" *ngIf="showMpg">
                    <span class="car-info-text-title">{{vehicle.MPG}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-MPG-Content">
                                <span class="tooltiptext" id="myMPGTooltip">MPG</span>
                                <img alt="tooltipIcon" class="question-mark" style=" vertical-align: none !important;"
                                    id="msrp-mpg-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                </p> -->
                <p class="car-info-text static-div-text black" *ngIf="showSeatCount">
                    <span class="car-info-text-title">{{vehicle.SeatCount}}</span>
                </p>
                <!-- <p class="car-info-text static-div-text black" *ngIf="showRange">
                    <span class="car-info-text-title">{{vehicle.Range}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-Range-Content">
                                <span class="tooltiptext" id="myRangeTooltip">Range</span>
                                <img alt="tooltipIcon" class="question-mark" style=" vertical-align: none !important;"
                                    id="msrp-range-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                </p> -->
                <p class="car-info-text static-div-text black">
                    <span class="car-info-text-title">{{vehicle.Fuel}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-Fuel-Content">
                                <span class="tooltiptext" id="myFuelTooltip">Fuel</span>
                                <img class="question-mark" alt="tooltipIcon" style=" vertical-align: none !important;"
                                    id="msrp-fuel-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                </p>
                <p class="car-info-text static-div-text storename black">
                    <span class="car-info-text-title">{{vehicle.StoreName}}</span>
                </p>
                <p class="car-info-text static-div-text distance black" *ngIf="vehicle.FormattedDistance">
                    <span class="car-info-text-title">{{vehicle.Distance}}</span>
                </p>
            </div>

            <!--Car Info details-->
            <div class="car-slider-car-info vehicle-section" *ngIf="i > 0"
                [ngClass]="{'car-info-text-non-modal': viewMode == 'non-modal'}">

                <div class="info-section-sticky-header" *ngIf="isSRP">
                    <img class="sticky-header-img" [src]="vehicle.ThumbImage" alt="car">
                    <div class="sticky-header-text">
                        <div class="sticky-header-title">{{vehicle.VehicleYear}} {{vehicle.VehicleMake}}
                            {{vehicle.VehicleModel}}</div>
                        <div class="sticky-header-secondary-title">{{vehicle.VehicleTrim}}</div>
                    </div>
                </div>

                <p class="car-info-text static-div-text mspr black">
                    <span class="car-info-text-title price"
                        *ngIf="vehicle.FormattedPrice != '$'">{{vehicle.FormattedPrice}}</span>
                    <span class="car-info-text-title price" *ngIf="vehicle.FormattedPrice == '$'">
                        <a class="phonenum-link" href="tel:{{vehicle?.StorePhoneNumber}}"
                            tabindex="0">{{vehicle?.StorePhoneNumber}}</a>
                    </span>
                </p>
                <p class="car-info-text static-div-text enginetext black" *ngIf="showEngine">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].Engine}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-Engine-Content">
                                <span class="tooltiptext" id="myEngineTooltip">Engine</span>
                                <img alt="tooltipIcon" class="question-mark" style=" vertical-align: none !important;"
                                    id="msrp-engine-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                    <span class="valign">{{vehicle.Engine}}</span>
                </p>
                <p class="car-info-text static-div-text black" *ngIf="showTransmission">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].Transmission}}</span>
                    <span class="valign">{{vehicle.Transmission}}</span>
                </p>
                <p class="car-info-text static-div-text black" *ngIf="showDriveType">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].VehicleDriveType}}</span>
                    <span class="valign">{{vehicle.VehicleDriveType}}</span>
                </p>
                <!-- <p class="car-info-text static-div-text black" *ngIf="showMpg">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].MPG}}</span>
                    <span class="valign">{{vehicle.MPG}}</span>
                </p> -->
                <p class="car-info-text static-div-text black" *ngIf="showSeatCount">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].SeatCount}}</span>
                    <span class="valign">{{vehicle.SeatCount}}</span>
                </p>
                <!-- <p class="car-info-text static-div-text black" *ngIf="showRange">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].Range}}</span>
                    <span class="valign">{{vehicle.Range}}</span>
                </p> -->
                <p class="car-info-text static-div-text black">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].Fuel}}
                        <span class="vin-tooltip" tabindex="0">
                            <span class="feature-text" id="vdp-Fuel-Content">
                                <span class="tooltiptext" id="myFuelTooltip">Fuel</span>
                                <img class="question-mark" alt="tooltipIcon" style=" vertical-align: none !important;"
                                    id="msrp-fuel-custom-tooltip"
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%235C5C5C;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(7 7)'%3E%3Cg transform='translate(-7 -7)'%3E%3Cpath class='a' d='M13.9,7A7,7,0,1,1,7,0a7,7,0,0,1,6.9,7Z' transform='translate(0.1 0.001)'/%3E%3Cpath class='b' d='M5.728,7.678c0-1.725,2.13-1.826,2.13-2.841s-.406-.812-1.217-.812a2.232,2.232,0,0,0-1.725.913L3.8,3.519A4.058,4.058,0,0,1,6.945,2.2c1.826,0,2.942.913,2.942,2.232S7.452,6.664,7.452,7.881a.812.812,0,0,0,.3.609L6.235,8.9a1.826,1.826,0,0,1-.507-1.217Zm0,3.145a1.116,1.116,0,1,1,1.116,1.116,1.116,1.116,0,0,1-1.116-1.116Z' transform='translate(0.157 0.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E">
                            </span>
                        </span>
                    </span>
                    <span class="valign">{{vehicle.Fuel}}</span>
                </p>
                <p class="car-info-text static-div-text storename black">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].StoreName}}</span>
                    <span class="valign">{{vehicle.StoreName}}</span>
                </p>
                <p class="car-info-text static-div-text distance black" *ngIf="vehicle.FormattedDistance">
                    <span class="car-info-text-title title-d-none">{{carsArray[0].Distance}}</span>
                    <span class="valign">{{vehicle.FormattedDistance}} miles away from {{vehicle.Zipcode}}</span>
                </p>
            </div>
        </div>
    </div>
</div>