import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2, isDevMode } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { VerifyEmailModalLibComponent } from './verify-email-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from './verify-email-modal.constants';

interface AnVerifyEmailModalElement {
  selector: HTMLElement;
  ref: ComponentRef<VerifyEmailModalLibComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AnVerifyEmailModalService {
  private renderer!: Renderer2;
  public isSRPSearch?: boolean = false;
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
    private http: HttpClient
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createVerifyEmailModalContainer();
  }

  triggerVerifyEmailModal(isSRP?: string): Observable<string> {
    this.isSRPSearch = isSRP && (isSRP == 'srp-search') ? true : false;
    const element = this.createVerifyEmailModalElement();
    this.renderer.addClass(document.body, 'fix-height');
    this.openVerifyEmailModal(element.selector);
    element.ref.instance.showVerifyEmailModal = true;
    return this.handleReturn(element);
  }

  private createVerifyEmailModalContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');

    const divModalContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divModalContainer, 'id', 'an-verify-email-modal-container-id');

    this.renderer.appendChild(divOverlayContainer, divModalContainer);
    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createVerifyEmailModalElement(): AnVerifyEmailModalElement {
    const selector = this.document.createElement('verify-email-modal-lib');
    const factory = this.componentFactoryResolver.resolveComponentFactory(VerifyEmailModalLibComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getVerifyEmailModalContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-verify-email-modal-container-id');
  }

  private openVerifyEmailModal(selector: HTMLElement) {
    const container = this.getVerifyEmailModalContainer();

    container ? this.renderer.appendChild(container, selector) : console.error('"an-verify-email-modal-container-id" does not exist');
  }

  closeVerifyEmailModal(element: AnVerifyEmailModalElement) {
    const container = this.getVerifyEmailModalContainer();
      if (container) {
        element.ref.instance.showVerifyEmailModal = false;
        this.renderer.removeClass(document.body, 'fix-height');
        this.renderer.removeChild(container, element.selector);
      } else {
        console.error('"an-verify-email-modal-container-id" does not exist');
      }
  }

  private handleReturn(element: AnVerifyEmailModalElement) {
    return element.ref.instance.onModalClose.asObservable().pipe(tap((doAuth) => {
      this.closeVerifyEmailModal(element);
    }));
  }

  verifyEmail() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    const url = isDevMode() ? `${API_URLS.LOCAL}${API_URLS.Verify_Email_API_URL}` : `${API_URLS.Verify_Email_API_URL}`;
    return this.http.get<any>(url, httpOptions);
  }

}
