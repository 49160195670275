import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'an-tooltip',
  templateUrl: './an-tooltip.component.html',
  styleUrls: ['./an-tooltip.component.scss']
})
export class AnTooltipComponent implements OnInit {

  @Input()
  tooltipTitle!: string;

  @Input()
  tooltipContent!: string;

  @Input()
  tooltipMarginLeft!: string;

  @Input()
  tooltipArrowMarginLeft!: string;

  @Input()
  tooltipMarginBottom!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
