import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NotificationModalLibComponent } from './notification-modal.component';
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
interface AnNotificationModalElement {
  selector: HTMLElement;
  ref: ComponentRef<NotificationModalLibComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AnNotificationModalService {
  private renderer!: Renderer2;
  public notifDetail?: any = null;
  public openNotificaion = new BehaviorSubject(false)
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
    private http: HttpClient
    
  ) {
    document.addEventListener('__OPEN__NOTIFICATION__MODAL__', (eventData: any) => {
      console.log('__OPEN__NOTIFICATION__MODAL__ received in breakdown component',eventData);
      this.openNotificaion.next(true);
    //  this.triggerNotificationModal(eventData.detail);
   });
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createNotificationModalContainer();
  }

  triggerNotificationModal(detail?: any, isEnabled?: boolean): Observable<string> {
    console.log('isEnabled', isEnabled);
    this.notifDetail = detail;
    const element = this.createNotificationModalElement();
    this.renderer.addClass(document.body, 'fix-height');
    this.openNotificationModal(element.selector,isEnabled);
    element.ref.instance.showNotificationModal = true;
    return this.handleReturn(element);
  }

  private createNotificationModalContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');

    const divModalContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divModalContainer, 'id', 'an-notification-modal-container-id');

    this.renderer.appendChild(divOverlayContainer, divModalContainer);
    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createNotificationModalElement(): AnNotificationModalElement {
    const selector = this.document.createElement('notification-modal-lib');
    const factory = this.componentFactoryResolver.resolveComponentFactory(NotificationModalLibComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getNotificationModalContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-notification-modal-container-id');
  }

  private openNotificationModal(selector: HTMLElement,isEnabled?: boolean) {
    const container = this.getNotificationModalContainer();
    this.openNotificaion.next(false);

    
    container
      ? this.renderer.appendChild(container, selector)
      : console.error('"an-notification-modal-container-id" does not exist');
    
  }

  closeNotificationModal(element: AnNotificationModalElement) {
    const container = this.getNotificationModalContainer();
      if (container) {
        element.ref.instance.showNotificationModal = false;
        this.renderer.removeClass(document.body, 'fix-height');
        this.renderer.removeChild(container, element.selector);
      } else {
        console.error('"an-notification-modal-container-id" does not exist');
      }
  }

  private handleReturn(element: AnNotificationModalElement) {
    return element.ref.instance.onModalClose.asObservable().pipe(tap((doAuth) => {
      this.closeNotificationModal(element);
    }));
  }

  getAlerts() {
    const url = '/my-account/notifications/api/sitecore/MyNotifications/getAlerts';
    return this.http.get(url);
  }

  updateAlerts(body:any) {  
    const UpdalertUrl = '/my-account/notifications/api/sitecore/MyNotifications/updateAlertStatus';
    return this.http.patch(UpdalertUrl, body);
  }
}
