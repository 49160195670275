export const mock = {
  "Data": [
    {
      "MSRP": "105500.0000",
      "Engine": "Electric Motor ",
      "Transmission": "Automatic",
      "VehicleDriveType": "Rear Wheel Drive",
      "MPGMPGeCity": "97",
      "SeatCount": "5",
      "Range": "350",
      "Fuel": "Electric Fuel System",
      "StoreName": "Mercedes-Benz of Fort Lauderdale",
      "StorePhoneNumber": "(954) 949-0623",
      "Distance": 1.9610541058697408,
      "Mileage": "85",
      "isCompareEnabled": true,
      "VehicleMake": "Mercedes-Benz",
      "VehicleModel": "EQS",
      "VehicleTrim": "EQS 450+ Sedan",
      "VehicleYear": "2022",
      "ThumbImage": "//az336639.vo.msecnd.net/actualcdn/a8ae8ffc6c445378afc0e1dfd0e9bb4b_392x294_Q75_V4.jpg",
      "Zipcode": "33301",
      "MPGMPGeHW": "97",
      "Vin": "W1KCG2DB8NA017510",
      "Condition": "NEW",
      "StockBgColor": "#3366cc"
    },
    {
      "MSRP": "82700.0000",
      "Engine": "Electric Motor ",
      "Transmission": "Automatic",
      "VehicleDriveType": "Rear Wheel Drive",
      "MPGMPGeCity": "94",
      "SeatCount": "5",
      "Range": "279",
      "Fuel": "Electric Fuel System",
      "StoreName": "Mercedes-Benz of Fort Lauderdale",
      "StorePhoneNumber": "(954) 949-0623",
      "Distance": 1.9610541058697408,
      "Mileage": "12",
      "isCompareEnabled": true,
      "VehicleMake": "Mercedes-Benz",
      "VehicleModel": "EQE",
      "VehicleTrim": "EQE 350+ SUV",
      "VehicleYear": "2023",
      "ThumbImage": "//az336639.vo.msecnd.net/actualcdn/226bdbd4234852e997008dfc9966eec9_392x294_Q75_V4.jpg",
      "Zipcode": "33301",
      "MPGMPGeHW": "87",
      "Vin": "4JGGM2BB1PA008315",
      "Condition": "NEW",
      "StockBgColor": "#3366cc"
    },
    {
      "MSRP": "82350.0000",
      "Engine": "Electric Motor ",
      "Transmission": "Automatic",
      "VehicleDriveType": "Rear Wheel Drive",
      "MPGMPGeCity": "94",
      "SeatCount": "5",
      "Range": "279",
      "Fuel": "Electric Fuel System",
      "StoreName": "Mercedes-Benz of Fort Lauderdale",
      "StorePhoneNumber": "(954) 949-0623",
      "Distance": 1.9610541058697408,
      "Mileage": "6",
      "isCompareEnabled": true,
      "VehicleMake": "Mercedes-Benz",
      "VehicleModel": "EQE",
      "VehicleTrim": "EQE 350+ SUV",
      "VehicleYear": "2023",
      "ThumbImage": "//az336639.vo.msecnd.net/actualcdn/924653cef81a5763a60baa364c68a7c4_392x294_Q75_V4.jpg",
      "Zipcode": "33301",
      "MPGMPGeHW": "87",
      "Vin": "4JGGM2BB4PA007157",
      "Condition": "NEW",
      "StockBgColor": "#3366cc"
    }
  ],
  "Status": true,
  "HttpStatusCode": 200,
  "ErrorMessage": "",
  "Exception": null
}