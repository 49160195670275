import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApptDateComponent } from './components/appt-date/appt-date.component';
import { ApptTimeComponent } from './components/appt-time/appt-time.component';
import { InputEmailComponent } from './components/input-email/input-email.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { InputStateComponent } from './components/input-state/input-state.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { AnTooltipModule } from '@autonation/dt-an-components-lib/an-tooltip';
import { TextAreaFieldComponent } from './components/textarea-field/textarea-field.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    AnTooltipModule
  ],
  declarations: [
    ApptDateComponent,
    ApptTimeComponent,
    InputEmailComponent,
    InputFieldComponent,
    InputStateComponent,
    CustomButtonComponent,
    TextAreaFieldComponent
  ],
  exports: [
    ApptDateComponent,
    ApptTimeComponent,
    InputEmailComponent,
    InputFieldComponent,
    InputStateComponent,
    CustomButtonComponent,
    TextAreaFieldComponent
  ]
})
export class AnAppointmentCommonModule { }
