import { Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';
import { ITealiumSettings } from '../models/tealium-settings';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  myGarageFavorites!: {
    EnableFavorites: boolean;
    FavoritesMaxLimit: number;
    EnableMyGarageFavorites: boolean;
  };

  tealiumSettings!: ITealiumSettings


  constructor() {
    if (isDevMode()) {
      this.myGarageFavorites = {
        EnableFavorites: true,
        FavoritesMaxLimit: 30,
        EnableMyGarageFavorites: true,
      };

      this.tealiumSettings = {
        TealiumAccount: 'autonation',
        TealiumENV: 'qa',
        TealiumProfile: 'sitecorean',
      };
    } else {
      this.readState();
    }
  }

  readState() {
    const myAppState = document.getElementById('my-app-state');

    if (myAppState) {
      const data = this.parseState(myAppState.innerText);
      console.log('reading state from favorite service', data);

      if (data.MyGarageFavorites) {
        this.myGarageFavorites = data.MyGarageFavorites;
        console.log('myGarageFavorites', this.myGarageFavorites);
      }

      if (data.TealiumSettings) {
        this.tealiumSettings = data.TealiumSettings;
      }
    }
  }

  parseState(jsonString: string): any {
    const value = jsonString
      .replace(new RegExp('&q;', 'g'), '"')
      .replace(new RegExp('&a;', 'g'), '&')
      .replace(new RegExp('&s;', 'g'), "'")
      .replace(new RegExp('&l;', 'g'), '<')
      .replace(new RegExp('&g;', 'g'), '>');
    return JSON.parse(value);
  }
}
