import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
  })
export class TealiumConfigService {
  script_src = "";

  constructor() {
    this.setUtagConfig();
  }

  private setUtagConfig() {
    (<any>window).utag_cfg_ovrd = { noview: true };
    (<any>window).utag_data = {};
  }

  getScript(src: string, callback?: () => void) {
    if (!src) {
      return;
    }

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.charset = "utf-8";
    s.src = src;

    s.onload = callback || function () { };

    const t = document.getElementsByTagName("script")[0];
    if(t && t.parentNode){
      t.parentNode.insertBefore(s, t);
    }
  }
  setConfig(config: { account: string; profile: string; environment: string }) {
    const { account, profile, environment } = config;

    if (account && profile && environment) {
      this.script_src = `https://tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js`;
    }
  }


  track(tealium_event: string, data?: any) {
    if (!this.script_src) {
      console.log("Tealium config not set.");
      return;
    }

    const trackEvent = () => (<any>window).utag.track(tealium_event, data);

    if (!(<any>window).utag) {
      this.getScript(this.script_src, trackEvent);
    } else {
      trackEvent();
    }
  }

  view(data?: any) {
    this.track("view", data);
  }

  link(data?: any) {
    this.track("link", data);
  }
}
