import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnSelectComponent } from './an-select.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AnSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ], 
  exports: [AnSelectComponent]
})
export class AnSelectModule { }
