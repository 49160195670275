import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeSrcPipe } from './safe-src.pipe';


@NgModule({
  declarations: [SafeSrcPipe],
  imports: [CommonModule],
  exports: [SafeSrcPipe]
})
export class SafeSrcPipeModule { }
