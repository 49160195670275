<div class="an-toast" role="alert" aria-live="assertive" aria-atomic="true" [@flyInOut]="transitionState"
  [ngStyle]="{'background-color': color}" [ngClass]="{'an-toast--padding': !showActionText && actionText}" *ngIf="showToast">
  <div class="an-toast__content" [ngClass]="{'an-toast__content-max-width': showActionText}">
    <div class="an-toast__icon">
      <div class="an-icon">

        <svg class="an-icon__check" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 13"
          *ngIf="actionText">
          <path id="Path_272983" data-name="Path 272983"
            d="M110.635,77.065a1.46,1.46,0,0,1,0-2.09,1.523,1.523,0,0,1,2.127,0l2.885,2.835,6.86-8.719a1.518,1.518,0,0,1,2.1-.266,1.457,1.457,0,0,1,.271,2.067l-7.834,9.957a1.515,1.515,0,0,1-2.322.235l-4.09-4.019Z"
            transform="translate(-110.194 -68.517)" [ngStyle]="{ fill: color }"></path>
        </svg>

        <svg class="an-icon__cross" width="19" height="19" viewBox="0 0 1024 1024" version="1.1" (click)="showToast=false"
          xmlns="http://www.w3.org/2000/svg" *ngIf="!actionText">
          <path
            d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"
            [ngStyle]="{ fill: color }" />
        </svg>

      </div>
    </div>
    <div class="an-content">
      <div class="an-content__title"> {{ title }} </div>
      <div class="an-content__message" *ngIf="message"> {{ message }} </div>
    </div>
  </div>

  <ng-container *ngIf="showActionText">
    <div class="an-toast__action" [attr.aria-label]="actionText" *ngIf="actionText" (click)="actionClick($event)">
      <span class="an-action__text"> {{ actionText }} </span>
    </div>
  </ng-container>
  
</div>