<div #modal aria-hidden="true" class="new-modal" role="dialog" *ngIf="showCompareVehicleModal">

  <div class="an-vehicle-modal modal-compare" id="container">
    <div class="modal-content-upper-section">
      <p class="modal-title">Compare your favorites
        <span class="modal-close" (click)="closeModal();">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <defs>
              <style>
                .a {
                  fill: #0a3453;
                  stroke: #0a3453;
                }

                .b {
                  fill: none;
                }
              </style>
            </defs>
            <path class="a"
              d="M15,14.317,8.181,7.5,15,.682,14.317,0,7.5,6.818.682,0,0,.682,6.818,7.5,0,14.317.682,15,7.5,8.181,14.317,15Z"
              transform="translate(13 13)" />
            <path class="b" d="M0,0H40V40H0Z" />
          </svg>
        </span>
      </p>
    </div>

    <ng-container>
      <lib-compare-blocks-ui></lib-compare-blocks-ui>
    </ng-container>
  </div>
</div>