import { ComponentFactoryResolver, Injectable, Injector, Inject, ApplicationRef, ComponentRef, Renderer2, RendererFactory2, isDevMode } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddVehicleComponent } from './add-vehicle.component';
import { IAddVehicle, IUpdateOwnedVehicleDetailsRequest, IVehicleColorsRequest, MMTRes } from './add-vehicle.interface';

interface IAddVehicleComponent {
  selector: HTMLElement;
  ref: ComponentRef<AddVehicleComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class AnAddVehicleService {
  private renderer!: Renderer2;
  vehicleData! : IAddVehicle;
  API_URLS = {
    LOCAL: 'http://localhost:4000',
    Update_Own_VehicleDetails_API_URL: '/my-account/my-vehicle/api/sitecore/MyGarage/updateMyOwnedVehicle?vehicleId={{vehicleId}}',
    Reject_Vehicle: '/my-account/my-vehicle/api/sitecore/MyGarage/rejectMyOwnedVehicle?vehicleId={{vehicleId}}',
    GET_VEHICLE_COLORS_URL: '/my-account/my-vehicle/api/sitecore/MyGarage/getVehicleColors?year={{year}}&make={{make}}&model={{model}}&trim={{trim}}',
    ymmtUrl : "/my-account/my-vehicle/api/sitecore/MyGarage/",
  };
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
    private http: HttpClient
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.createAddVehicleModalContainer();
  }
  
  
  triggerAddVehicleModal(data:IAddVehicle): Observable<string> {
    this.vehicleData = data;
    const element = this.createAddVehcileModalElement();
    this.renderer.addClass(document.body, 'fix-height');
    this.openAddVehicleModal(element.selector);
    element.ref.instance.showAddVehicleModal = true;
    return this.handleReturn(element);
  }

  updateOwnVehicleDetails(vehicleId: string, req: IUpdateOwnedVehicleDetailsRequest) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let url = isDevMode() ? `${this.API_URLS.LOCAL}${this.API_URLS.Update_Own_VehicleDetails_API_URL}` : `${this.API_URLS.Update_Own_VehicleDetails_API_URL}`;
    url = url.replace("{{vehicleId}}", vehicleId);
    return this.http.put<IUpdateOwnedVehicleDetailsRequest>(url, req, httpOptions);
  }
  rejectOwnVehicleDetails(vehicleId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let url = isDevMode() ? `${this.API_URLS.LOCAL}${this.API_URLS.Reject_Vehicle}` : `${this.API_URLS.Reject_Vehicle}`;
    url = url.replace("{{vehicleId}}", vehicleId);
    return this.http.put<IUpdateOwnedVehicleDetailsRequest>(url, null, httpOptions);
  }

  getVehicleColors(request: IVehicleColorsRequest) {
    const { year, make, model, trim, vin } = request;
    let url = isDevMode() ? `${this.API_URLS.LOCAL}${this.API_URLS.GET_VEHICLE_COLORS_URL}` : `${this.API_URLS.GET_VEHICLE_COLORS_URL}`;
    url = url.replace("{{year}}", year).replace("{{make}}", make).replace("{{model}}", model).replace("{{trim}}", trim);
    if (vin) {
      url += `&vin=${vin}`
    }
    return this.http
      .get<any>(url);
  }

  getTrims(modelId: string) {
    let url = isDevMode() ? `${this.API_URLS.LOCAL}${this.API_URLS.ymmtUrl}` : `${this.API_URLS.ymmtUrl}`;
    return this.http.get<MMTRes>(`${url}getTrims?modelId=${modelId}`)
  }


  private createAddVehicleModalContainer() {
    const divOverlayContainer = this.renderer.createElement('div');
    // this.renderer.setAttribute(divOverlayContainer, 'aria-live', 'polite');

    const divModalContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(divModalContainer, 'id', 'an-add-vehicle-modal-container-id');

    this.renderer.appendChild(divOverlayContainer, divModalContainer);
    this.renderer.appendChild(this.document.body, divOverlayContainer);
  }

  private createAddVehcileModalElement(): IAddVehicleComponent {
    const selector = this.document.createElement('add-vehicle-modal-lib');
    const factory = this.componentFactoryResolver.resolveComponentFactory(AddVehicleComponent);
    const ref = factory.create(this.injector, [], selector);
    this.applicationRef.attachView(ref.hostView);
    return { selector, ref }
  }

  private getAddVehicleModalContainer(): HTMLElement | null {
    return this.document.body.querySelector('#an-add-vehicle-modal-container-id');
  }

  private openAddVehicleModal(selector: HTMLElement) {
    const container = this.getAddVehicleModalContainer();

    container ? this.renderer.appendChild(container, selector) : console.error('"an-add-vehicle-modal-container-id" does not exist');
  }

  closeAddVehicleModal(element: IAddVehicleComponent, doAuth: any) {
    if(!(doAuth.action === 'add' || doAuth.action === 'remove')) {
    const container = this.getAddVehicleModalContainer();
      if (container) {
        element.ref.instance.showAddVehicleModal = false;
        this.renderer.removeClass(document.body, 'fix-height');
        this.renderer.removeChild(container, element.selector);
      } else {
        console.error('"an-add-vehicle-modal-container-id" does not exist');
      }
    }
  }

  private handleReturn(element: IAddVehicleComponent) {
    return element.ref.instance.onModalClose.asObservable().pipe(tap((doAuth) => {
      this.closeAddVehicleModal(element, doAuth);
    }));
  }
}
