import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'an-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.scss']
})
export class CloseIconComponent {
  @Output() public onClick = new EventEmitter();

  @Input()
  size: 24 | 32 = 24;
}
