<div class="an-base-modal" [style]="{'background-color': color}">
  <div class="an-base-modal__header">
    <an-close-icon class="an-base-modal__close" (onClick)="onCloseModal.emit()" *ngIf="!isLargeIcon; else largeicon"></an-close-icon>
    <ng-template #largeicon>
      <an-close-icon [size]="32" class="an-base-modal__close" (onClick)="onCloseModal.emit()"></an-close-icon>
    </ng-template>
  </div>

  <div class="an-base-modal__content">
    <ng-content></ng-content>
  </div>
</div>