import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AnSaveFavoritesComponent } from './an-save-favorites.component';
import { AnToastModule } from '@autonation/dt-an-components-lib/an-toast';
import { AnAuthenticationModule } from '@autonation/dt-an-components-lib/authentication-modal';
import { AnHeartIconModule } from '@autonation/dt-an-components-lib/an-heart-icon';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [
    AnSaveFavoritesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AnToastModule,
    AnAuthenticationModule,
    AnHeartIconModule
  ],
  providers:[],
  exports: [
    AnSaveFavoritesComponent
  ]
})
export class AnSaveFavoritesModule { }
