<div class="an-appt-time">
  <h2 class="an-appt-time__title"> {{ timeTitle }} </h2>

  <div class="form-group">
    <select class="form-control an-appt-time__control" [formControl]="timeControl">
      <option *ngFor="let hour of hours" [value]="hour">
        {{ hour | lowercase }}
      </option>
    </select>
  </div>
</div>