
<div class="form-group formGroup">
  <label [class.show-asterisk]="showAsterisk" [for]="id">{{ label }}</label>
  <select #anSelect
  (change) = "setSelectedOptionClass(anSelect)"
    class="form-control defaultSelect"
    [class.error]="parent.control?.errors && parent.control?.dirty"
    [id]="isDefaultNotNeeded ? idValue : id"
    [ngClass]="{'locked-icon': lockedIcon, 'enableCustomcss': enableCustomcss}"
    [compareWith]="compareWith"
    [formControl]="selectListControl"
  >
    <option [ngValue]="null" class="defaultSelect" *ngIf="!isDefaultNotNeeded">{{ placeholder }}</option>
    <option
      *ngFor="let item of _items"
      [ngValue]="item"
      class="optionSelected"
    >
      {{ item.description }}
    </option>
  </select>
  <span class="validation">
    <i *ngIf="parent.control?.errors && parent.control?.dirty" class="invalid-icon">
      <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m11.9495 2.05c-.979-.97889-2.22628-1.645507-3.58413-1.915546s-2.76528-.13137363-4.04432.398461c-1.27904.529835-2.37225 1.427045-3.14139 2.578175-.769135 1.15112-1.17966 2.50447-1.17966 3.88891s.410525 2.73779 1.17966 3.8889c.76914 1.1511 1.86235 2.0484 3.14139 2.5782s2.68647.6685 4.04432.3985c1.35785-.2701 2.60513-.9367 3.58413-1.9156.6501-.65 1.1658-1.4217 1.5176-2.27105.3518-.84932.5329-1.75963.5329-2.67895 0-.91931-.1811-1.82962-.5329-2.67895-.3518-.84932-.8675-1.62103-1.5176-2.27105zm-2.45001 6.72c.04996.04677.08978.1033.11701.16608.02722.06279.04127.13049.04127.19892s-.01405.13614-.04127.19892c-.02723.06279-.06705.11931-.11701.16608-.04672.04634-.10212.083-.16304.10789-.06092.02488-.12615.03749-.19195.03711-.13118-.00055-.25688-.05263-.35001-.145l-1.79499-1.795-1.77 1.795c-.09314.09237-.21884.14445-.35.145-.06581.00038-.13104-.01223-.19196-.03711-.06092-.02489-.11632-.06155-.16304-.10789-.09313-.09368-.1454-.2204-.1454-.3525 0-.13209.05227-.25882.1454-.3525l1.77-1.795-1.77-1.77c-.08192-.09565-.12472-.21869-.11986-.34452.00486-.12584.05703-.24521.14607-.33426.08905-.08905.20842-.14121.33426-.14607s.24887.03794.34453.11985l1.77 1.77 1.77-1.77c.04629-.04793.10156-.08627.16267-.11284.06112-.02657.12686-.04084.19349-.04201.06662-.00116.13282.01082.19482.03524.062.02443.11858.06082.16651.10711.04794.04629.08628.10157.11285.16268s.04084.12686.042.19348c.00116.06663-.01081.13283-.03523.19483-.02443.062-.06082.11858-.10712.16651l-1.795 1.77z" fill="#d0021b"/></svg>
    </i>
  </span>
  <div>
    <i *ngIf="parent.control?.errors && errorMsg" class="error-msg">
      {{ errorMsg }}
    </i>
  </div>
</div>
