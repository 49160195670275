import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DecimalNumberPipe } from './decimal-number.pipe';

@NgModule({
  declarations: [DecimalNumberPipe],
  imports: [CommonModule],
  providers: [DecimalPipe],
  exports: [DecimalNumberPipe]
})
export class DecimalNumberPipeModule {}
