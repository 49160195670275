import { Component, Inject, OnInit } from '@angular/core';
import { AnModalService, MODAL_DATA } from '@autonation/dt-an-components-lib/an-modal';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit {

  constructor(@Inject(MODAL_DATA) public data: { department: any }, private modalService: AnModalService) { }

  ngOnInit(): void {
  }

  get currentDay(): number {
    return new Date().getDay() - 1;
  }

  close() {
    this.modalService.close();
  }

}
