import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafeUrlPipeModal } from './pipes/safe-url.pipe';
import { VerifyEmailModalLibComponent } from './verify-email-modal.component';




@NgModule({
  declarations: [
    VerifyEmailModalLibComponent,
    SafeUrlPipeModal
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    VerifyEmailModalLibComponent
  ]
})
export class AnVerifyEmailModule { }
