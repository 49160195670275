import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'compare-vehicle',
  templateUrl: './compare-vehicle.component.html',
  styleUrls: ['./compare-vehicle.component.scss']
})
export class CompareVehicleComponent implements OnInit, AfterViewInit {

  showCompareVehicleModal: boolean = false;

  @Output() onModalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  closeModal() {
    this.onModalClose.emit({ action: 'close' });
  }

  /* istanbul ignore next */
  @HostListener('window:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.showCompareVehicleModal === true) {
      event.preventDefault();
      //this.controlTabNavigation(event);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.closeModal();
      event.preventDefault();
    }
  }

}
