import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_DATA } from '../constants';
import { AnModalService } from '../an-modal.service';

@Component({
  selector: 'an-appointment-modal-v2',
  templateUrl: './appointment-modal-v2.component.html',
  styleUrls: ['./appointment-modal-v2.component.scss']
})
export class AppointmentModalV2Component implements OnInit {

  iframeHeight = '100%';
  iFrameLoaded = false;
  isConfirmationPage = false;

  constructor(@Inject(MODAL_DATA) public data: any, private modalService: AnModalService) { }


  ngOnInit(): void {
    window.addEventListener('message', e => {
      if (e.data.type === 'appointment_confirmation') {
        this.isConfirmationPage = true;
      }
    });
  }

  onLoadIframe(e: any) {
    if (e.target.src) {
      this.iFrameLoaded = true;
    }
  }

  close() {
    this.data?.onData?.next({
      confirm: this.isConfirmationPage
    });
    
    this.modalService.close();
  }

}
