import { Component, Inject, Input, OnInit, Injector } from '@angular/core';
import { AnModalService } from '@autonation/dt-an-components-lib/an-modal';
import { MODAL_DATA } from '@autonation/dt-an-components-lib/an-modal/src/constants';
import { AnAuthenticationModalService } from '@autonation/dt-an-components-lib/authentication-modal';


@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

  constructor(@Inject(MODAL_DATA) public data: any, private modalService: AnModalService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.close()
  }

}
