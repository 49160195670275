import { Component, EventEmitter, HostListener, OnInit, Output, } from '@angular/core';
import { apptModalImage, bas64Image, srpSearchModalImage } from './authentication-modal.constants';
import { AnAuthenticationModalService } from './authentication-modal.service';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'authentication-modal-lib',
   templateUrl: './authentication-modal.component.html',
   styleUrls: ['./authentication-modal.component.scss']
})
export class AuthenticationModalLibComponent implements OnInit {
   showAuthenticationModal: boolean = false;
   @Output() onModalClose: EventEmitter<string> = new EventEmitter<string>();
   isOwnershipAppt?: boolean = false;
   isSRPSearch?: boolean = false;
   modalImage = bas64Image;
   apptModalImage = apptModalImage;
   srpSearchModalImage = srpSearchModalImage;

   constructor(private authenticationModalService: AnAuthenticationModalService) {

   }
   /* istanbul ignore next */
   @HostListener('window:keydown', ['$event'])
   onKeyDownHandler(event: KeyboardEvent) {
      if (event.key === 'Tab' && this.showAuthenticationModal === true) {
         event.preventDefault();
         this.controlTabNavigation(event);
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
         this.closeAuthModal();
      }
   }

   ngOnInit() {
      this.isOwnershipAppt = this.authenticationModalService.isOwnershipAppt;
      this.isSRPSearch = this.authenticationModalService.isSRPSearch;
      
      console.log('isOwnershipAppt - ', this.isOwnershipAppt);
      console.log('isSRPSearch - ', this.isSRPSearch);
   }

   controlTabNavigation(event: any) {
      var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
      let modalBox = document.getElementById('auth-modal-centents');
      if (modalBox) {
         const keyboardfocusableElements = Array.from(modalBox.querySelectorAll(focusableElementsString));
         const numberOfFocusableItems = keyboardfocusableElements.length;
         const firstFocusableElement = keyboardfocusableElements[0];
         const lastFocusableElement = keyboardfocusableElements[numberOfFocusableItems - 1];
         const focusedItem = document.activeElement;
         const focusedItemIndex = focusedItem ? keyboardfocusableElements.indexOf(focusedItem) : -1;

         if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
               (lastFocusableElement as HTMLElement).focus();
            } else {
               (keyboardfocusableElements[focusedItemIndex - 1] as HTMLElement).focus();
            }
         } else {
            if (document.activeElement === lastFocusableElement) {
               (firstFocusableElement as HTMLElement).focus();
            } else {
               (keyboardfocusableElements[focusedItemIndex + 1] as HTMLElement).focus();
            }
         }
      }
      event.preventDefault();
   }

   closeAuthModal(doAuth?: string) {
      const returnFlag = doAuth ? doAuth : '';
      this.showAuthenticationModal = false;
      this.onModalClose.emit(returnFlag);
   }

}
