import { Injectable } from '@angular/core';
import { TealiumConfigService } from './tealium-config.service';
import {
  CategoryL2,
  ITealiumRequest,
  TealiumEvent,
  TealiumEventCategory,
  TealiumEventLabel,
  UDO,
  tealiumEventAction,
} from '../models/tealium-settings';

@Injectable({
  providedIn: 'root',
})
export class TealiumCallService {
  constructor(private _tealium: TealiumConfigService) {}

  signIn(pageGroup: string) {
    try {
      const signIn: ITealiumRequest = {
        category_l1: pageGroup,
        category_l2: CategoryL2.MyFavorites,
        category_l3: UDO.NotSet,
        category_l4: UDO.NotSet,
        event: TealiumEvent.LogIn,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup),
        event_label: TealiumEventLabel.LogInClick,
        event_value: 1,
        pagegroup: pageGroup,
      };

      (window as any).utag_data = signIn;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }

  createAccount(pageGroup: string) {
    try {
      const createAccount: ITealiumRequest = {
        category_l1: pageGroup,
        category_l2: CategoryL2.MyFavorites,
        category_l3: UDO.NotSet,
        category_l4: UDO.NotSet,
        event: TealiumEvent.SignUp,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup),
        event_label: TealiumEventLabel.SignUpClick,
        event_value: 1,
        pagegroup: pageGroup,
      };

      (window as any).utag_data = createAccount;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }

  exitModal(pageGroup: string) {
    try {
      const exitModal: ITealiumRequest = {
        category_l1: pageGroup,
        category_l2: CategoryL2.MyFavorites,
        category_l3: UDO.NotSet,
        category_l4: UDO.NotSet,
        event: TealiumEvent.ExitModal,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup),
        event_label: TealiumEventLabel.ExitedOut,
        event_value: 1,
        pagegroup: pageGroup,
      };

      (window as any).utag_data = exitModal;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }

  favoritesError(pageGroup: string) {
    try {
      const favError: ITealiumRequest = {
        event: TealiumEvent.ErrorSaveVehicle,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup.toUpperCase()),
        event_label: TealiumEventLabel.ErrorSaveVehicle,
        event_value: 1,
        pagegroup: pageGroup,
      };

      (window as any).utag_data = favError;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }

  favoriteRemovalUndo(pageGroup: string) {
    try {
      const favRemoval: ITealiumRequest = {
        category_l1: pageGroup.toUpperCase(),
        category_l2: UDO.NotSet,
        category_l3: UDO.NotSet,
        category_l4: UDO.NotSet,
        event: TealiumEvent.UndoRemovedFromFavorites,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup.toUpperCase()),
        event_label: TealiumEventLabel.UndoRemovedFromFavorites,
        event_value: 1,
        pagegroup: pageGroup.toUpperCase(),
      };

      (window as any).utag_data = favRemoval;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }

  maxFavouritesReached(pageGroup: string) {
    try {
      const maxFavReached: ITealiumRequest = {
        event: TealiumEvent.FavReachedSaveVehicle,
        event_category: TealiumEventCategory.UserActivityType,
        event_action: tealiumEventAction(pageGroup.toUpperCase()),
        event_label: TealiumEventLabel.FavReachedSaveVehicle,
        event_value: 1,
        pagegroup: pageGroup,
      };

      (window as any).utag_data = maxFavReached;
      this._tealium.link((window as any).utag_data);
    } catch (error) {
      console.log(error);
    }
  }
}
