export interface FavoriteVehicles {
  Vehicles: {
    maxFavoriteVehiclesLimit: number;
    favoriteVehiclesData: FavoriteVehicleData;
  };
  Success: boolean;
  ErrorMessages: any;
}

export interface FavoriteVehicleData {
  count: number;
  favoriteVehicles: FavoriteVehicle[];
}

export interface FavoriteVehicle {
  vin: string;
  dealDetails?: any;
  vehicleDetail?: any;
}

export interface SaveVehicle {
  data: {
    maxFavoriteVehiclesLimit: number;
    saveFavoriteVehicleData: {
      updatedFavoriteCount: number;
      vin: string;
    }
  }
  Success: boolean;
  ErrorMessages: any;
}

export interface RemoveVehicle {
  data: {
    maxFavoriteVehiclesLimit: number;
    favoriteVehiclesResponse: {
      updatedFavoriteCount: number;
    }
  }
  Success: boolean;
  ErrorMessages: any;
}

export enum AuthActions {
  Login = 'login',
  Register = 'register',
}
