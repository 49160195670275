import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MODAL_DATA } from '../constants';

@Component({
  selector: 'an-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Output() onClickButton1 = new EventEmitter();
  @Output() onClickButton2 = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    @Inject(MODAL_DATA) public data: any
  ) { }

  closeModal() {
    this.onClose.emit();
  }
}
