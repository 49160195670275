<div class="an-input-field form-group">
  <label [for]="'id-' + label" class="an-input-field__label"> {{ label }} </label>
  <ng-content #help></ng-content>
  <input type="text" [maxlength]="maxlength" class="an-input-field__control form-control" [id]="'id-' + label"
    [placeholder]="placeholder" [mask]="mask || ''" [formControl]="inputControl" (keypress)="keyPress($event)" (keydown)="onKeydown.emit($event)">
  <a href="javascript:void(0)" class="an-input-field__clear" *ngIf="hasError" (click)="clear()"></a>
  <div class="an-input-field__error-message" *ngIf="hasError">
    {{ inputControl.hasError('required') ? requiredErrorMessage : invalidErrorMessage }}
  </div>
  <ng-content #autocomplete></ng-content>
</div>